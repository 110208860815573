import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import axios from 'axios';
import * as authHelper from '../../../auth/core/AuthHelpers';
import { useAuth } from '../../../auth/core/Auth';
import Select from 'react-select';
import config from 'config'

const countries = require('country-data').countries;

// interface PartnerData {
//   PartnerName: string;
//   PartnerCategoryID: number;
//   Country: string;
//   Address1: string;
//   Address2: string;
//   City: string;
//   State: string;
//   Pincode: string;
//   Latitude: string;
//   Longitude: string;
//   IsOnline:boolean
// }

const validationSchema = Yup.object().shape({
  PartnerName: Yup.string().transform((value) => value.trim()).required('PartnerName is required'),
  PartnerCategoryID: Yup.number().notOneOf([0], 'Category is required'),
  Country: Yup.string().transform((value) => value.trim()).required('Country is required'),
  Address1: Yup.string().transform((value) => value.trim()).required('Address1 is required'),
  Address2: Yup.string().transform((value) => value.trim()).required('Address2 is required'),
  City: Yup.string().transform((value) => value.trim()).required('City is required'),
  State: Yup.string().transform((value) => value.trim()).required('State is required'),
  Pincode: Yup.string().transform((value) => value.trim()).required('Pincode is required'),
  // Latitude: Yup.string().required('Latitude is required'),
  // Longitude: Yup.string().required('Longitude is required'),
});

let initialValues: any = {
  PartnerName: '',
  PartnerCategoryID: 0,
  Country: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Pincode: '',
  Latitude: '',
  Longitude: '',
  isOnline:false
};

export function AddEditAhPartner() {
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setcategoryOptions] = useState([{ ID: '', CategoryName: '' }]);
  const [CountryOptions, setCountryOptions] = useState([{ countryCallingCodes: '', countryName: '' }]);
  const TOKEN = authHelper.getAuth();
  const { saveAuth, setCurrentUser } = useAuth();
  const { partnerId } = useParams<{ partnerId: string }>();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      // Handle form submission
      // Create form data with only the changed values
      let Data: any = {};
      let error = false
      const appendFormData = (fieldName: string, fieldValue: any) => {
        const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;
        if (trimmedValue === '') {
          formik.setFieldError(fieldName, `${fieldName} is required.`)
          error = true
        }
        if (trimmedValue !== initialValues[fieldName]) {
          Data[fieldName] = trimmedValue;
        }
      };

      appendFormData('PartnerName', values.PartnerName);
      appendFormData('PartnerCategoryID', Number(values.PartnerCategoryID));
      appendFormData('Country', values.Country);
      appendFormData('City', values.City);
      appendFormData('State', values.State);
      appendFormData('Address1', values.Address1);
      appendFormData('Address2', values.Address2);
      appendFormData('Latitude', values.Latitude);
      appendFormData('Longitude', values.Longitude);
      appendFormData('Pincode', values.Pincode);
      appendFormData('IsOnline', values.IsOnline === true ? '1' : '0');
      if (error) {
        return
      }
      // You can now use the 'formData' object as needed
      // Display the formData object in the console
      if (partnerId) {
        axios
          .put(`${config.SERVER_URL}/ahpartner/updateahpartner/${partnerId}`, Data, {
            headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
          }).then((res: any) => {
            console.log(res)
            if (res.data.message) {
              console.log(res.message)
              navigate('/apps/ah-partner-management/ah-partners')
            }
          }).catch((err: any) => {
            console.log(err)
            saveAuth(undefined);
            setCurrentUser(undefined);
          })
      } else {
        axios
          .post(`${config.SERVER_URL}/ahpartner/addahpartners`, Data, {
            headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
          }).then((res: any) => {
            console.log(res)
            if (res.data.message) {
              navigate('/apps/ah-partner-management/ah-partners')
            }
          }).catch((err: any) => {
            console.log(err)
            saveAuth(undefined);
            setCurrentUser(undefined);
          })
      }

      setLoading(false);
    },
  });

  const getCategories = () => {
    axios
      .get(`${config.SERVER_URL}/ahpartner/getahpartnercategorywithpagination?page=1`, {
        headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
      })
      .then((res) => {
        console.log(res)
        if (res.data.ahPartnerCategory.length > 0) {
          setcategoryOptions(res.data.ahPartnerCategory)
        }
      })
      .catch((err) => {
        saveAuth(undefined);
        setCurrentUser(undefined);
      });
  };

  const getAhPartner = () => {

    axios
      .get(`${config.SERVER_URL}/ahpartner/getallahpartnersbyid/${partnerId}`, {
        headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
      })
      .then((res) => {
        console.log(res.data)
        if (res.data) {
          const data = res.data;
          // Assign API data to form fields
          formik.setValues({
            PartnerName: data.PartnerName ? data.PartnerName : initialValues.PartnerName,
            PartnerCategoryID: data.PartnerCategoryID ? data.PartnerCategoryID : initialValues.PartnerCategoryID,
            Country: data.Country ? data.Country : initialValues.Country,
            Address1: data.Address1 ? data.Address1 : initialValues.Address1,
            Address2: data.Address2 ? data.Address2 : initialValues.Address2,
            City: data.City ? data.City : initialValues.City,
            State: data.State ? data.State : initialValues.State,
            Pincode: data.Pincode ? data.Pincode : initialValues.Pincode,
            Latitude: data.Latitude ? data.Latitude : initialValues.Latitude,
            Longitude: data.Longitude ? data.Longitude : initialValues.Longitude,
            IsOnline: data.IsOnline === 1 ? true : false,
          });

          initialValues.PartnerName = data.PartnerName
          initialValues.PartnerCategoryID = data.PartnerCategoryID
          initialValues.Country = data.Country
          initialValues.State = data.State
          initialValues.City = data.City
          initialValues.Address2 = data.Address2
          initialValues.Address1 = data.Address1
          initialValues.Pincode = data.Pincode
          initialValues.Latitude = data.Latitude
          initialValues.Longitude = data.Longitude
          initialValues.IsOnline = data.IsOnline === 1 ? true : false
        } else {
          saveAuth(undefined);
          setCurrentUser(undefined);
        }
      })
      .catch((err) => {
        saveAuth(undefined);
        setCurrentUser(undefined);
      });
  };
  console.log(formik.values.IsOnline)
  useEffect(() => {
    if (partnerId) {
      getAhPartner()
    } else {
      formik.setValues({
        PartnerName: '',
        PartnerCategoryID: 0,
        Country: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Pincode: '',
        Latitude: '',
        Longitude: '',
        IsOnline: false,
      })
    }
    getCategories()

    var result: any = [];
    var processedCountries: any = {};
    for (var countryCode in countries) {
      if (countries.hasOwnProperty(countryCode) && countryCode !== 'all') {
        var country = countries[countryCode];
        var countryCallingCodes = country.countryCallingCodes;
        var countryName = country.name;
        if (!processedCountries[countryCallingCodes] && !processedCountries[countryName]) {
          var countryObject = {
            countryCallingCodes: countryCallingCodes,
            countryName: countryName
          };
          result.push(countryObject);
          processedCountries[countryCallingCodes] = true;
          processedCountries[countryName] = true;
        }
      }
    }
    setCountryOptions(result)
  }, [])
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? 'transparent' : provided.borderColor,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      border: 'none',
      outline: state.isFocused ? 'none' : 'none',
      backgroundColor: "#F5F8FA",
      marginTop: '5px',
      width: '520px',
    }),
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">

        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="PartnerName" className="form-label fs-6 fw-bolder text-dark">
            PartnerName
          </label>
          <input
            id="PartnerName"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.PartnerName && formik.errors.PartnerName },
              // { 'is-valid': formik.touched.Name && !formik.errors.Name }
            )}
            {...formik.getFieldProps('PartnerName')}
            onChange={formik.handleChange}
          />
          {formik.touched.PartnerName && formik.errors.PartnerName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.PartnerName)}</span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="CategoryID" className="form-label fs-6 fw-bolder text-dark">
            Category
          </label>

          <Select
            options={categoryOptions}
            getOptionLabel={(option) => option.CategoryName}
            getOptionValue={(option) => option.ID}
            className={clsx('form-control p-0 bg-transparent', {
              'is-invalid': formik.touched.PartnerCategoryID && formik.errors.PartnerCategoryID,
            })}
            styles={customStyles}
            value={categoryOptions.find((option: any) => option.ID === formik.values.PartnerCategoryID)}
            onChange={(selectedOption) => formik.setFieldValue('PartnerCategoryID', selectedOption?.ID || '')}
            placeholder="Select Category"
            onBlur={formik.handleBlur('PartnerCategoryID')}
          />
          {formik.touched.PartnerCategoryID && formik.errors.PartnerCategoryID && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.PartnerCategoryID)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="Address1" className="form-label fs-6 fw-bolder text-dark">
            Address 1
          </label>
          <input
            id="Address1"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.Address1 && formik.errors.Address1 },
              // { 'is-valid': formik.touched.Address1 && !formik.errors.Address1 }
            )}
            {...formik.getFieldProps('Address1')}
            onChange={formik.handleChange}
          />
          {formik.touched.Address1 && formik.errors.Address1 && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.Address1)}</span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="Address2" className="form-label fs-6 fw-bolder text-dark">
            Address 2  </label>
          <input
            id="Address2"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.Address2 && formik.errors.Address2 },
              // { 'is-valid': formik.touched.Address2 && !formik.errors.Address2 }
            )}
            {...formik.getFieldProps('Address2')}
            onChange={formik.handleChange}
          />
          {formik.touched.Address2 && formik.errors.Address2 && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.Address2)}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">

        <div className="form-group fv-row col-lg-6 mb-8"  >
          <label htmlFor="Country" className="form-label fs-6 fw-bolder text-dark">
            Country
          </label>
          <Select
            options={CountryOptions}
            styles={customStyles}
            getOptionLabel={(option) => option.countryName}
            getOptionValue={(option) => option.countryName}
            className={clsx('form-control p-0 bg-transparent', {
              'is-invalid': formik.touched.Country && formik.errors.Country,
            })}
            value={CountryOptions.find((option: any) => option.countryName === formik.values.Country)}
            onChange={(selectedOption) => formik.setFieldValue('Country', selectedOption?.countryName || '')}
            placeholder="Select Country"
            onBlur={formik.handleBlur('Country')}
          />
          {formik.touched.Country && formik.errors.Country && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.Country)}</span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="source" className="form-label fs-6 fw-bolder text-dark">
            State
          </label>
          <input
            id="source"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.State && formik.errors.State },
              // { 'is-valid': formik.touched.source && !formik.errors.source }
            )}
            {...formik.getFieldProps('State')}
            onChange={formik.handleChange}
          />
          {formik.touched.State && formik.errors.State && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.State)}</span>
              </div>
            </div>
          )}
        </div>

      </div>


      <div className="row">

        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="city" className="form-label fs-6 fw-bolder text-dark">
            City
          </label>
          <input
            id="city"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.City && formik.errors.City },
              // { 'is-valid': formik.touched.City && !formik.errors.City }
            )}
            {...formik.getFieldProps('City')}
            onChange={formik.handleChange}
          />
          {formik.touched.City && formik.errors.City && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.City)}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="Pincode" className="form-label fs-6 fw-bolder text-dark">
            Pincode
          </label>
          <input
            id="Pincode"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.Pincode && formik.errors.Pincode },
              // { 'is-valid': formik.touched.source && !formik.errors.source }
            )}
            {...formik.getFieldProps('Pincode')}
            onChange={(e) => {
              const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
              formik.handleChange({
                  target: {
                      id: 'Pincode',
                      name: 'Pincode',
                      value: onlyNumbers,
                  },
              });
          }}
          />
          {formik.touched.Pincode && formik.errors.Pincode && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.Pincode)}</span>
              </div>
            </div>
          )}
        </div>
      </div>


      <div className="row">
        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="Latitude" className="form-label fs-6 fw-bolder text-dark">
            Latitude
          </label>
          <input
            id="Latitude"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.Latitude && formik.errors.Latitude },
              // { 'is-valid': formik.touched.Latitude && !formik.errors.Latitude }
            )}
            {...formik.getFieldProps('Latitude')}
            onChange={(e) => {
              const onlyNumbers = e.target.value.replace(/[^0-9.]/g, '');
              formik.handleChange({
                  target: {
                      id: 'Latitude',
                      name: 'Latitude',
                      value: onlyNumbers,
                  },
              });
          }}
          />
          {formik.touched.Latitude && formik.errors.Latitude && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.Latitude)}</span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row col-lg-6 mb-8">
          <label htmlFor="Longitude" className="form-label fs-6 fw-bolder text-dark">
            Longitude  </label>
          <input
            id="Longitude"
            type="text"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.Longitude && formik.errors.Longitude },
              // { 'is-valid': formik.touched.Longitude && !formik.errors.Longitude }
            )}
            {...formik.getFieldProps('Longitude')}
            onChange={(e) => {
              const onlyNumbers = e.target.value.replace(/[^0-9.]/g, '');
              formik.handleChange({
                  target: {
                      id: 'Longitude',
                      name: 'Longitude',
                      value: onlyNumbers,
                  },
              });
          }}
          />
          {formik.touched.Longitude && formik.errors.Longitude && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{String(formik.errors.Longitude)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fv-row col-lg-3 mb-8">
                    <label htmlFor="IsOnline" className="form-label fs-6 fw-bolder text-dark">
                        IsOnline
                    </label>
                    <input
                        id="IsOnline"
                        type="checkbox"
                        className={clsx(
                            'form-check-input mx-6',
                            {
                                'is-invalid': formik.touched.IsOnline && formik.errors.IsOnline,
                            },
                            {
                                // 'is-valid': formik.touched.IsOnline && !formik.errors.IsOnline,
                            }
                        )}
                        checked={formik.values.IsOnline}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.IsOnline && formik.errors.IsOnline && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{String(formik.errors.IsOnline)}</span>
                            </div>
                        </div>
                    )}
                </div>


      <div className="fv-row mb-8">
        <button
          type="submit"
          className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        <div onClick={() => navigate(-1)}
          className="btn btn-secondary fw-bolder fs-6 px-8 py-4 my-3 me-3"
        >
          Cancel
        </div>
      </div>
    </form>
  );
};

