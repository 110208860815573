import axios from 'axios'
import React, {useEffect, useState} from 'react'
// import { NavLink } from 'react-router-dom'
import {useAuth} from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers'
import {Modal, Button} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactPaginate from 'react-paginate'
import '../../css/pagination.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {getBucketURL} from '../../../../utils/Index'
import config from 'config'

const validationSchema = Yup.object().shape({
  CategoryName: Yup.string()
    .transform((value) => value.trim())
    .required('Category Name is required'),
  CategoryImage: Yup.string().required('Image is required'),
})
export default function AhPartenrCategoryList() {
  const {saveAuth, setCurrentUser} = useAuth()
  const TOKEN = authHelper.getAuth()
  const [loading, setLoading] = useState(true)
  const [AhPartenrCategory, setAhPartenrCategory] = useState([])
  const [CategoryID, setCategoryID] = useState(null)
  const [editCategory, seteditCategory] = useState(false)
  const [submitCategory, setsubmitCategory] = useState(false)
  const [showModal, setShowModal] = useState(false)
  let [pagenumber, setPagenumber] = useState(1)
  const pagesize = 10
  const [length, setLength] = useState(0)
  let pageCount = Math.ceil(length / pagesize)

  const formik = useFormik({
    initialValues: {
      CategoryName: '',
      CategoryImage: '',
      AHPartnerCategoryImage: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      const trimmedCategoryName = values.CategoryName.trim()

      // Check if the DiagnosisName is empty after removing spaces
      if (trimmedCategoryName === '') {
        formik.setFieldError('CategoryName', 'Category Name is required')
        formik.setFieldValue('CategoryName', '')
        return
      }
      setsubmitCategory(true)
      let formData = new FormData()
      formData.append('CategoryName', trimmedCategoryName)
      if (values.CategoryImage !== '') {
        formData.append('AHPartnerCategoryImage', values.AHPartnerCategoryImage)
      }
      if (!editCategory) {
        axios
          .post(`${config.SERVER_URL}/ahpartner/addahpartnercategory`, formData, {
            headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
          })
          .then((res) => {
            console.log(res)
            if (res.data.message) {
              handleClose()
              getAhMemberCategories(pagenumber)
            }
          })
          .catch((err) => {
            console.log(err)
            formik.setFieldError('CategoryName', err.response.data.message)
            setsubmitCategory(false)
            // Handle the error
          })
      } else {
        axios
          .put(
            `${config.SERVER_URL}/ahpartner/updateahpartnercategory/${CategoryID}`,
            formData,
            {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
          )
          .then((res) => {
            console.log(res)
            if (res.data.message) {
              handleClose()
              getAhMemberCategories(pagenumber)
            }
          })
          .catch((err) => {
            console.log(err)
            formik.setFieldError('CategoryName', err.response.data.message)
            setsubmitCategory(false)
            // Handle the error
          })
      }
    },
  })

  const handleShow = (item: any) => {
    setsubmitCategory(false)
    setShowModal(true)
    seteditCategory(true)
    formik.setValues({
      CategoryName: item.CategoryName,
      CategoryImage: item.ThumbnailURL,
      AHPartnerCategoryImage: '',
    })
    setCategoryID(item.ID)
  }
  const openModal = () => {
    setsubmitCategory(false)
    setShowModal(true)
  }
  const changePage = ({selected}: any) => {
    setPagenumber(selected + 1)
    pagenumber = selected + 1
    getAhMemberCategories(pagenumber)
  }

  const deleteCategory = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'you want to delete this category ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(
                `${config.SERVER_URL}/ahpartner/deleteahpartnercategory/${id}`,
                {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
              )
              .then((res) => {
                if (res.data.message) {
                  getAhMemberCategories(pagenumber)
                }
              })
          },
        },
        {
          label: 'Cancel',
        },
      ],
    })
  }

  const handleClose = () => {
    setShowModal(false)
    seteditCategory(false)
    setsubmitCategory(false)
    setCategoryID(null)
    formik.resetForm()
  }
  const handleCategoryImageChange = async (e: any, setFieldValue: any) => {
    if (e.target.value) {
      const file = e.target.files[0]
      // Handle file change logic

      setFieldValue('CategoryImage', URL.createObjectURL(file))
      setFieldValue('AHPartnerCategoryImage', file)
    }
  }

  const getAhMemberCategories = (pagenumber: any) => {
    setLoading(true)
    try {
      axios
        .get(
          config.SERVER_URL +
            `/ahpartner/getahpartnercategorywithpagination?page=${pagenumber}&limit=${pagesize}`,
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res: any) => {
          if (res.data) {
            setLoading(false)
            if (res.data.ahPartnerCategory.length !== 0) {
              setAhPartenrCategory(res.data.ahPartnerCategory)
              setLength(res.data.totalRecords)
            } else {
              if (pagenumber > 1) {
                getAhMemberCategories(pagenumber - 1)
                setPagenumber(pagenumber - 1)
              }
            }
          } else {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
        .catch((err) => {
          console.log(err)
          setCurrentUser(undefined)
          saveAuth(undefined)
        })
    } catch (err) {
      console.log(err)
      setCurrentUser(undefined)
      saveAuth(undefined)
    }
  }

  useEffect(() => {
    getAhMemberCategories(1)
  }, [])

  useEffect(() => {
    getURl(formik?.values?.CategoryImage, formik?.setFieldValue)
  }, [showModal])

  const getURl = async (url: any, setFieldValue: any) => {
    let imageUrl = await getBucketURL(url)
    setFieldValue('CategoryImage', imageUrl)
  }

  return (
    <>
      <div className='m-0 p-0 h-100'>
        <div className='col-lg-18 grid-margin stretch-card '>
          <div className='card'>
            <div className='card-body'>
              {/* <h4 className="card-title">General Questions</h4> */}
              <p className='card-description d-flex justify-content-end'>
                <button type='button' className='btn btn-primary' onClick={openModal}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Category
                </button>
              </p>
              {AhPartenrCategory.length > 0 ? (
                <div>
                  {' '}
                  <div className='bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive'>
                    <table className='table table-striped h-100'>
                      <thead>
                        <tr>
                          <th className='fw-bolder'>Category Image</th>
                          <th className='fw-bolder'>Category Name</th>
                          <th className='fw-bolder'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AhPartenrCategory.map((item: any, index) => {
                          return (
                            <RenderImage
                              item={item}
                              index={index}
                              handleShow={handleShow}
                              deleteCategory={deleteCategory}
                              key={index}
                            />
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='m-auto mt-6'>
                    {AhPartenrCategory.length > 0 ? (
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={'paginationbtns'}
                        previousLinkClassName={'prevBtn'}
                        nextLinkClassName={'nextBtn'}
                        disabledClassName={'paginationDisabled'}
                        activeClassName={'paginationActive'}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : loading ? (
                <span className='indicator-progress text-center' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <div className='text-center w-90'>
                  <h2 className='mt-9'>Records not found</h2>
                </div>
              )}{' '}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editCategory ? 'Edit Category' : 'Add Category'}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
              <div className='col-lg-8'>
                <div
                // className='image-input image-input-outline text-center'
                // data-kt-image-input='true'
                // style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                  // className='image-input-wrapper w-125px h-125px '
                  // style={{backgroundImage: `url(${toAbsoluteUrl(formik.values.CategoryImage)})`}}
                  >
                    <img
                      src={
                        formik.values.CategoryImage
                          ? formik.values.CategoryImage
                          : '/media/avatars/blank.png'
                      }
                      alt={formik.values.CategoryName}
                      style={{width: '100px', height: '100px', objectFit: 'contain'}}
                      loading='lazy'
                    />
                  </div>
                  {formik.touched.CategoryImage && formik.errors.CategoryImage && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.CategoryImage)}</span>
                      </div>
                    </div>
                  )}
                  <label
                    htmlFor='contained-button-file'
                    className='btn btn-primary fw-bolder fs-6 px-8 py-4 my-3'
                  >
                    Upload
                    <input
                      type='file'
                      accept='image/*'
                      style={{display: 'none'}}
                      id='contained-button-file'
                      name='CategoryImage'
                      onChange={(e) => handleCategoryImageChange(e, formik.setFieldValue)}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor='categoryName'>Category Name</label>
              <input
                type='text'
                className={`form-control ${
                  formik.touched.CategoryName && formik.errors.CategoryName ? 'is-invalid' : ''
                }`}
                id='CategoryName'
                name='CategoryName'
                value={formik.values.CategoryName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.CategoryName && formik.errors.CategoryName && (
                <div className='invalid-feedback'>{formik.errors.CategoryName}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button variant='primary' disabled={submitCategory} type='submit'>
              {!submitCategory ? 'Save' : 'Submiting'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const RenderImage = (props: any) => {
  const {item, index, handleShow, deleteCategory} = props
  const [image, setImage] = useState('')

  useEffect(() => {
    getURl(item?.ThumbnailURL)
  }, [])

  const getURl = async (url: any) => {
    let imageUrl = await getBucketURL(url)
    setImage(imageUrl)
  }
  return (
    <tr key={index}>
      <td>
        {/* <div
          className='image-input '
          data-kt-image-input='true'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
          }}
        >
          <div
            className='image-input-wrapper w-35px h-35px '
            style={{
              backgroundImage: `url(${image})`,
            }}
          ></div>
        </div> */}
        <div>
          <img
            src={image}
            alt=''
            style={{width: '50px', height: '50px', objectFit: 'contain'}}
            loading='lazy'
          />
        </div>
      </td>
      <td>{item.CategoryName ? item.CategoryName : '-'}</td>

      <td>
        <div className='d-flex '>
          <div style={{cursor: 'pointer'}} onClick={() => handleShow(item)}>
            <i className='fa fa-edit fa-5x fs-2' />
          </div>

          <div
            style={{cursor: 'pointer', marginLeft: '20px'}}
            onClick={() => deleteCategory(item.ID)}
          >
            {' '}
            <i className='fa fa-trash fa-5x fs-2' />
          </div>
        </div>
      </td>
    </tr>
  )
}
