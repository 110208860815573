import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import VitalSignList from './vital-sign/VitalSignList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Vital Sign Names',
    path: '/apps/vital-sign-management/vital-sign-names',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FamousPeople = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='vital-sign-names'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Vital Sign Names</PageTitle>
           <VitalSignList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/vital-sign-management/vital-sign-names' />} />
  </Routes>
  )
}

export default FamousPeople
