import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers'
// import { Modal, Button } from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactPaginate from 'react-paginate'
import '../../css/pagination.css'
import {getBucketURL} from '../../../../utils/Index'
import config from 'config'

export default function AhPartnerServiceList() {
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()
  const TOKEN = authHelper.getAuth()
  const [ahPartnerServiceList, setahPartnerServiceList] = useState([])
  let [pagenumber, setPagenumber] = useState(1)
  const [loading, setLoading] = useState(true)
  const pagesize = 10
  const [length, setLength] = useState(0)
  let pageCount = Math.ceil(length / pagesize)

  const AddService = () => {
    navigate('/apps/ah-partner-service-management/add-service')
  }
  const EditService = (id: string) => {
    navigate('/apps/ah-partner-service-management/edit-service/' + id)
  }
  const changePage = ({selected}: any) => {
    setPagenumber(selected + 1)
    pagenumber = selected + 1
    getahPartnerServiceList(pagenumber)
  }

  const deleteService = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'you want to delete this entry ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(
                `${config.SERVER_URL}/ahpartner/deleteahpartnerservicemaster/${id}`,
                {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
              )
              .then((res) => {
                if (res.data.message) {
                  getahPartnerServiceList(pagenumber)
                }
              })
          },
        },
        {
          label: 'Cancel',
        },
      ],
    })
  }

  const getahPartnerServiceList = (pagenumber: any) => {
    setLoading(true)
    try {
      axios
        .get(
          config.SERVER_URL +
            `/ahpartner/getallahpartnerservicemasterwithpagination?page=${pagenumber}&limit=${pagesize}`,
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res: any) => {
          console.log(res)
          if (res.data) {
            setLoading(false)
            if (res.data.ahPartnerServiceMaster.length !== 0) {
              setahPartnerServiceList(res.data.ahPartnerServiceMaster)
              setLength(res.data.totalRecords)
            } else {
              if (pagenumber > 1) {
                getahPartnerServiceList(pagenumber - 1)
                setPagenumber(pagenumber - 1)
              }
            }
          } else {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
        .catch((err) => {
          console.log(err)
          setCurrentUser(undefined)
          saveAuth(undefined)
        })
    } catch (err) {
      console.log(err)
      setCurrentUser(undefined)
      saveAuth(undefined)
    }
  }
  useEffect(() => {
    getahPartnerServiceList(1)
  }, [])

  return (
    <div className='m-0 p-0 h-100'>
      <div className='col-lg-18 grid-margin stretch-card '>
        <div className='card'>
          <div className='card-body'>
            {/* <h4 className="card-title">General Questions</h4> */}
            <p className='card-description d-flex justify-content-end'>
              <button type='button' className='btn btn-primary' onClick={AddService}>
                <KTIcon iconName='plus' className='fs-2' />
                Add Service
              </button>
            </p>
            {ahPartnerServiceList.length > 0 ? (
              <div>
                {' '}
                <div className='bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive'>
                  <table className='table table-striped h-100'>
                    <thead>
                      <tr>
                        <th className='fw-bolder'>Image</th>
                        <th className='fw-bolder'>ServiceName</th>
                        <th className='fw-bolder'>Description</th>
                        <th className='fw-bolder'>Price</th>
                        <th className='fw-bolder'>Offer</th>
                        <th className='fw-bolder'>IsOnline</th>
                        <th className='fw-bolder'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ahPartnerServiceList.map((item: any, index) => {
                        console.log('ldfljsjlfdk', item)
                        return (
                          <RenderImage
                            item={item}
                            index={index}
                            EditService={EditService}
                            deleteService={deleteService}
                            key={index}
                            ahPartnerServiceList={ahPartnerServiceList}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className='m-auto mt-6'>
                  {ahPartnerServiceList.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={'paginationbtns'}
                      previousLinkClassName={'prevBtn'}
                      nextLinkClassName={'nextBtn'}
                      disabledClassName={'paginationDisabled'}
                      activeClassName={'paginationActive'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : loading ? (
              <span className='indicator-progress text-center' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <div className='text-center w-90'>
                <h2 className='mt-9'>Records not found</h2>
              </div>
            )}{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderImage = (props: any) => {
  const {item, index, EditService, deleteService, ahPartnerServiceList} = props
  const [image, setImage] = useState('')
  console.log('item', item)
  useEffect(() => {
    getURl(item?.ThumbnailURL)
  }, [ahPartnerServiceList])

  const getURl = async (url: any) => {
    let imageUrl = await getBucketURL(url)
    console.log('imageUrl', imageUrl)
    setImage(imageUrl)
  }
  return (
    <tr key={index}>
      <td>
        {/* <div
          className='image-input '
          data-kt-image-input='true'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
        >
          <div
            className='image-input-wrapper w-35px h-35px '
            style={{
              backgroundImage: `url(${image ? image : toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
            }}
          ></div>
        </div> */}
        <div>
          <img
            src={image}
            alt=''
            style={{width: '50px', height: '50px', objectFit: 'contain'}}
            loading='lazy'
          />
        </div>
      </td>
      <td>{item.ServiceName ? item.ServiceName : '-'}</td>
      <td>{item.Description ? item.Description : '-'}</td>
      <td>{item.Price ? item.Price : '-'}</td>
      <td>{item.Offer ? item.Offer : '-'}</td>
      <td>{item.IsOnline === 1 ? 'Yes' : 'No'}</td>

      <td>
        <div className='d-flex '>
          <div style={{cursor: 'pointer'}} onClick={() => EditService(item.ID)}>
            <i className='fa fa-edit fa-5x fs-2' />
          </div>

          <div
            style={{cursor: 'pointer', marginLeft: '20px'}}
            onClick={() => deleteService(item.ID)}
          >
            {' '}
            <i className='fa fa-trash fa-5x fs-2' />
          </div>
        </div>
      </td>
    </tr>
  )
}
