import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const menuItems = [
    // { to: '/dashboard', icon: 'element-11', title: intl.formatMessage({ id: 'MENU.DASHBOARD' }), fontIcon: 'bi-app-indicator' },
    {
      to: '/apps/user-management/users',
      icon: 'abstract-28',
      title: 'User management',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/famous-peoples-category-management/famous-peoples/categories',
      icon: 'abstract-28',
      title: 'Famous peoples category',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/famous-peoples-management/famous-peoples',
      icon: 'abstract-28',
      title: 'Famous peoples',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/vital-sign-management/vital-sign-names',
      icon: 'abstract-28',
      title: 'Vital Sign Names',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/diagnosis-management/diagnosis',
      icon: 'abstract-28',
      title: 'Diagnosis',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/ethnicity-management/ethnicities',
      icon: 'abstract-28',
      title: 'Ethnicity',
      fontIcon: 'bi-layers',
    },
    // {
    //   to: '/apps/ah-partner-category-management/categories',
    //   icon: 'abstract-28',
    //   title: 'Ah Partner Category',
    //   fontIcon: 'bi-layers',
    // },
    // {
    //   to: '/apps/ah-partner-management/ah-partners',
    //   icon: 'abstract-28',
    //   title: 'Ah Partner',
    //   fontIcon: 'bi-layers',
    // },
    // {
    //   to: '/apps/ah-partner-service-management/services',
    //   icon: 'abstract-28',
    //   title: 'Ah Partner Services',
    //   fontIcon: 'bi-layers',
    // },
    {
      to: '/apps/limited-time-offer-management/limited-time-offer',
      icon: 'abstract-28',
      title: 'Limited Time Offer',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/feedback-management/feedbacks',
      icon: 'abstract-28',
      title: 'Feedback Management',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/notifications-management/notifications',
      icon: 'abstract-28',
      title: 'Notifications Management',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/apple-a-day-management/apple',
      icon: 'abstract-28',
      title: 'Apple A Day Management',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/menstrual-cycle-product',
      icon: 'abstract-28',
      title: 'Womens Health Feedback',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/mens-health-product',
      icon: 'abstract-28',
      title: 'Mens Health Feedback',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/partner-product-management/partner-product',
      icon: 'abstract-28',
      title: 'AH Partner Product',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/ah-new-partner-management/ah-new-partners',
      icon: 'abstract-28',
      title: 'AH Partner',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/question-master-management/question-masters',
      icon: 'abstract-28',
      title: 'Question Master',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/body-parts-management/bodyparts',
      icon: 'abstract-28',
      title: 'Body Parts',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/symptoms-management/symptoms',
      icon: 'abstract-28',
      title: 'Symptoms',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/answer-master-management/answer-masters',
      icon: 'abstract-28',
      title: 'Answer Master',
      fontIcon: 'bi-layers',
    },
    {
      to: '/apps/reward-master-management/rewards',
      icon: 'abstract-28',
      title: 'Reward Master',
      fontIcon: 'bi-layers',
    },
  ]

  // Sort the array alphabetically based on the title
  const sortedMenuItems = menuItems.sort((a, b) => a.title.localeCompare(b.title))

  return (
    <>
      {/* <div className='sidebar-title'>
        <h3>  Service Selection  </h3>
      </div> */}

      {sortedMenuItems.map((item, index) => (
        <SidebarMenuItem
          key={index}
          to={item.to}
          icon={item.icon}
          title={item.title}
          fontIcon={item.fontIcon}
        />
      ))}
    </>
  )
}

export {SidebarMenuMain}
