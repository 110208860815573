import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import DiagnosisList from './diagnosis/DiagnosisList'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Diagnosis',
    path: '/apps/diagnosis-management/diagnosis',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Diagnosis = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='diagnosis'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Diagnosis</PageTitle>
           <DiagnosisList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/diagnosis-management/diagnosis' />} />
  </Routes>
  )
}

export default Diagnosis
