import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import clsx from 'clsx';
import config from 'config';

const validationSchema = Yup.object().shape({
    QuestionMasterID: Yup.number().notOneOf([0], 'Question is required'),
    DiagnosisID: Yup.number().notOneOf([0], 'Diagnosis is required'),
    Answer: Yup.string().transform((value) => value.trim()).required('Answer is required'),
});

export default function AnswerMasterList() {
    const { saveAuth, setCurrentUser } = useAuth()
    const TOKEN = authHelper.getAuth()
    const [loading, setLoading] = useState(true)
    const [AnswerMasterList, setAnswerMasterList] = useState([])
    const [QuestionMasterList, setQuestionMasterList] = useState([{ ID: '', Question: '' }])
    const [diagnosisOptions, setdiagnosisOptions] = useState([{ ID: '', DiagnosisName: '' }]);
    const [editAnswerMaster, seteditAnswerMaster] = useState(false);
    const [submitAnswerMaster, setsubmitAnswerMaster] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let [pagenumber, setPagenumber] = useState(1)
    const pagesize = 10
    const [length, setLength] = useState(0)
    let pageCount = Math.ceil(length / pagesize)

    const handleShow = (item: any) => {
        setsubmitAnswerMaster(false)
        setShowModal(true);
        seteditAnswerMaster(true);
        formik.setValues({ ...item })
        getQuestions(item.DiagnosisID)
    };
    const openModal = () => {
        setsubmitAnswerMaster(false)
        setShowModal(true);
    };
    const changePage = ({ selected }: any) => {
        setPagenumber(selected + 1)
        pagenumber = selected + 1
        getAnswerMaster(pagenumber)
    }


    const deleteAnswerMaster = (id: any) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'you want to delete this entry ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.delete(`${config.SERVER_URL}/diagnosis/deleteanswermasters/${id}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                            if (res.data.message) {
                                getAnswerMaster(pagenumber)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });


    }

    const handleClose = () => {
        setShowModal(false);
        setsubmitAnswerMaster(false)
        seteditAnswerMaster(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            ID: '',
            QuestionMasterID: 0,
            Answer: '',
            ShortDescription: '',
            Category: '',
            DiagnosisID: 0
        },
        validationSchema,
        onSubmit: (values) => {
            setsubmitAnswerMaster(true);

            // Trim the values before sending the request
            const trimmedValues = {
                ...values,
                Answer: values.Answer.trim(),
                ShortDescription: values.ShortDescription.trim(),
                Category: values.Category.trim()
            };

            if (!editAnswerMaster) {
                axios.post(`${config.SERVER_URL}/diagnosis/addanswermasters`, trimmedValues, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res);
                    if (res.data.message) {
                        handleClose();
                        getAnswerMaster(pagenumber);
                    }
                });
            } else {
                axios.put(`${config.SERVER_URL}/diagnosis/updateanswermasters/${values.ID}`, trimmedValues, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res);
                    if (res.data.message) {
                        handleClose();
                        getAnswerMaster(pagenumber);
                    }
                });
            }
        },
    });
    const getAnswerMaster = (pagenumber: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/diagnosis/getallanswermasters?page=${pagenumber}&limit=${pagesize}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    if (res.data.answerMasters.length !== 0) {
                        setAnswerMasterList(res.data.answerMasters)
                        setLength(res.data.totalRecords)
                    } else {
                        if (pagenumber > 1) {
                            getAnswerMaster(pagenumber - 1)
                            setPagenumber(pagenumber - 1)
                        }
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }

    const getQuestions = (id: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/diagnosis/getallquestionmasterswithpagination?page=1&DiagnosisID=${id}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    if (res.data.questionMasters) {
                        setQuestionMasterList(res.data.questionMasters)
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }
    const getDiagnosys = () => {
        axios
            .get(`${config.SERVER_URL}/diagnosis/getallquestionmasterswithpagination?page=1`, {
                headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
            })
            .then((res) => {
                console.log(res)
                if (res.data.questionMasters.length > 0) {
                    console.log('kalu', res.data.questionMasters)
                    const uniqueArray: any = [];
                    const uniqueIds = new Set();

                    res.data.questionMasters.forEach((item: any) => {
                        if (!uniqueIds.has(item.DiagnosisID)) {
                            uniqueArray.push({
                                ID: item.DiagnosisID,
                                DiagnosisName: item.DiagnosisName
                            });
                            uniqueIds.add(item.DiagnosisID);
                        }
                    });

                    setdiagnosisOptions(uniqueArray)
                }
            })
            .catch((err) => {
                saveAuth(undefined);
                setCurrentUser(undefined);
            });
    };
    useEffect(() => {
        getAnswerMaster(1)
        getDiagnosys()
    }, [])
    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: state.isFocused ? 'transparent' : provided.borderColor,
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            border: 'none',
            outline: state.isFocused ? 'none' : 'none',

        }),
    };
    return (<>
        <div className="m-0 p-0 h-100">
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">General Questions</h4> */}
                        <p className="card-description d-flex justify-content-end">
                            <button type='button' className='btn btn-primary' onClick={openModal}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Answer master
                            </button>
                        </p>
                        {AnswerMasterList.length > 0 ? <div> <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive" >
                            <table className="table table-striped h-100">
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Question</th>
                                        <th className='fw-bolder'>Answer</th>
                                        <th className='fw-bolder'>Short Description</th>
                                        <th className='fw-bolder'>Category</th>
                                        <th className='fw-bolder'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        AnswerMasterList.map((item: any, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.Question ? item.Question : '-'}
                                                </td>
                                                <td>
                                                    {item.Answer ? item.Answer : '-'}
                                                </td>
                                                <td>
                                                    {item.ShortDescription ? item.ShortDescription : '-'}
                                                </td>
                                                <td>
                                                    {item.Category ? item.Category : '-'}
                                                </td>

                                                <td>
                                                    <div className='d-flex '>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}  ><i className="fa fa-edit fa-5x fs-2" /></div>

                                                        <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteAnswerMaster(item.ID)}> <i className="fa fa-trash fa-5x fs-2" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className='m-auto mt-6'>
                                {AnswerMasterList.length > 0 ? <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationbtns"}
                                    previousLinkClassName={"prevBtn"}
                                    nextLinkClassName={"nextBtn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> : ""}
                            </div>
                        </div> : loading ? (
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        ) : <div className='text-center w-90'><h2 className='mt-9'>Records not found</h2></div>
                        } </div></div></div></div>
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{editAnswerMaster ? 'Edit Answer Master' : 'Add Answer Master'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <label className="col-lg-4 col-form-label fw-bold fs-6"></label>
                    </div>
                    <div className="form-group mb-5 mt-5" >
                        <label htmlFor="DiagnosisID">
                            Diagnosis Name
                        </label>
                        <Select
                            options={diagnosisOptions}
                            styles={customStyles}
                            getOptionLabel={(option) => option.DiagnosisName}
                            getOptionValue={(option) => option.ID}
                            classNamePrefix="select diagnosis"
                            className={clsx('form-control bg-transparent p-0', {
                                'is-invalid': formik.touched.DiagnosisID && formik.errors.DiagnosisID,
                            })}
                            value={diagnosisOptions.find((option: any) => option.ID === formik.values.DiagnosisID)}
                            onChange={(selectedOption) => {
                                formik.setFieldValue('DiagnosisID', selectedOption?.ID || '');
                                formik.setFieldValue('QuestionMasterID', 0)
                                getQuestions(selectedOption?.ID);
                            }}
                            placeholder="Select Diagnosis"
                            onBlur={formik.handleBlur('DiagnosisID')}

                        />

                        <div className="invalid-feedback">{formik.errors.DiagnosisID}</div>
                    </div>

                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="QuestionMasterID">Question</label>
                        <Select
                            id="QuestionMasterID"
                            name="QuestionMasterID"
                            className={clsx('form-control bg-transparent p-0', {
                                'is-invalid': formik.touched.QuestionMasterID && formik.errors.QuestionMasterID,
                            })}
                            getOptionLabel={(option) => option.Question}
                            getOptionValue={(option) => option.ID}
                            value={formik.values.QuestionMasterID ? QuestionMasterList.find((option: any) => option.ID === formik.values.QuestionMasterID) : null} // Change == to ===
                            options={QuestionMasterList}
                            styles={customStyles}
                            onChange={(selectedOption: any) => formik.setFieldValue('QuestionMasterID', selectedOption?.ID || '')}
                            placeholder="Select Question"
                        />

                        <div className="invalid-feedback">{formik.errors.QuestionMasterID}</div>

                    </div>
                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="price">Answer</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.Answer && formik.errors.Answer ? 'is-invalid' : ''}`}
                            id="Answer"
                            name="Answer"
                            value={formik.values.Answer}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.Answer && formik.errors.Answer && (
                            <div className="invalid-feedback">{formik.errors.Answer}</div>
                        )}
                    </div>
                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="price">Short Description</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.ShortDescription && formik.errors.ShortDescription ? 'is-invalid' : ''}`}
                            id="shortDescription"
                            name="ShortDescription"
                            value={formik.values.ShortDescription}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.ShortDescription && formik.errors.ShortDescription && (
                            <div className="invalid-feedback">{formik.errors.ShortDescription}</div>
                        )}
                    </div>
                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="price">Category</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.Category && formik.errors.Category ? 'is-invalid' : ''}`}
                            id="category"
                            name="Category"
                            value={formik.values.Category}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.Category && formik.errors.Category && (
                            <div className="invalid-feedback">{formik.errors.Category}</div>
                        )}
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitAnswerMaster} type="submit">
                        {!submitAnswerMaster ? 'Save' : 'Submitting'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
    )
}
