const env = process.env.REACT_APP_ADMIN_ENV;

const config = {
  development: {
    SERVER_URL: process.env.REACT_APP_DEV_SERVER_URL,
    AWS_S3_BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME_DEV,
    AWS_KEY : process.env.REACT_APP_AWS_KEY,
    AWS_SECRET_KEY : process.env.REACT_APP_AWS_SECRET_KEY,
    AWS_REGION : process.env.REACT_APP_AWS_REGION,
  },
  production: {
    SERVER_URL: process.env.REACT_APP_PROD_SERVER_URL,
    AWS_S3_BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME_PRD,
    AWS_KEY : process.env.REACT_APP_AWS_KEY,
    AWS_SECRET_KEY : process.env.REACT_APP_AWS_SECRET_KEY,
    AWS_REGION : process.env.REACT_APP_AWS_REGION,
  }
};

export default config[env];