import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth';
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap';
import { KTIcon } from '../../../../../_metronic/helpers';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from 'config';

const validationSchema = Yup.object().shape({
  VitalSignName: Yup.string().transform((value) => value.trim()).required('Vital Sign Name is required'),
});

export default function VitalSignList() {
  const { saveAuth, setCurrentUser } = useAuth();
  const TOKEN = authHelper.getAuth();
  const [VitalSignList, setVitalSignList] = useState([]);
  const [VitalSignName, setVitalSignName] = useState('');
  const [VitalSignID, setVitalSignID] = useState(null);
  const [editVitalSign, setEditVitalSign] = useState(false);
  const [submitVitalSign, setSubmitVitalSign] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [length, setLength] = useState(0);
  let pageCount = Math.ceil(length / pageSize);
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    setShowModal(false);
    setEditVitalSign(false);
    setSubmitVitalSign(false);
    setVitalSignName('');
    setVitalSignID(null);
    formik.resetForm();
  };


  const handleShow = (item:any) => {
    setShowModal(true);
    setEditVitalSign(true);
    setVitalSignName(item.VitalSignName);
    formik.setValues({VitalSignName:item.VitalSignName})
    setVitalSignID(item.ID);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const changePage = ({ selected }:any) => {
    setPageNumber(selected + 1);
    getVitalSignNames(selected + 1);
  };

  const deleteVitalSign = (id:any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to delete this entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(
                `${config.SERVER_URL}/vitalsign/deletevitalsignnames/${id}`,
                { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
              )
              .then((res) => {
                if (res.data.message) {
                  getVitalSignNames(pageNumber);
                }
              });
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  const getVitalSignNames = (pageNumber:any) => {
    setLoading(true)
    try {
      axios
        .get(
          `${config.SERVER_URL}/vitalsign/getvitalsignnames?page=${pageNumber}&limit=${pageSize}`,
          { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
        )
        .then((res) => {
          if (res.data) {
            setLoading(false)
            if (res.data.vitalSignNames.length !== 0) {
              setVitalSignList(res.data.vitalSignNames);
              setLength(res.data.totalRecords);
            } else {
              if (pageNumber > 1) {
                getVitalSignNames(pageNumber - 1);
                setPageNumber(pageNumber - 1);
              }
            }
          } else {
            saveAuth(undefined);
            setCurrentUser(undefined);
          }
        })
        .catch((err) => {
          console.log(err);
          setCurrentUser(undefined);
          saveAuth(undefined);
        });
    } catch (err) {
      console.log(err);
      setCurrentUser(undefined);
      saveAuth(undefined);
    }
  };

  useEffect(() => {
    getVitalSignNames(1);
  }, []);

  const formik = useFormik({
    initialValues: {
      VitalSignName: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      // You can access the form values using `values.VitalSignName`
      const trimmedVitalSignName = values.VitalSignName.trim();

      // Check if the DiagnosisName is empty after removing spaces
      if (trimmedVitalSignName === '') {
        formik.setFieldError('VitalSignName', 'Vital Sign Name is required');
        formik.setFieldValue('VitalSignName','')
        return;
      }
      setSubmitVitalSign(true);
      if (!editVitalSign) {
        axios
          .post(
            `${config.SERVER_URL}/vitalsign/addvitalsignnames`,
            { VitalSignName:trimmedVitalSignName },
            { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
          )
          .then((res) => {
            console.log(res);
            if (res.data.message) {
              handleClose();
              formik.resetForm();
              setSubmitVitalSign(false)
              getVitalSignNames(pageNumber);
            }
          }).catch((err:any)=>{
            console.log(err)
            setSubmitVitalSign(false);
            formik.setFieldError('VitalSignName',err.response.data.message)
          });
      } else {
        axios
          .put(
            `${config.SERVER_URL}/vitalsign/updatevitalsignnames/${VitalSignID}`,
            { VitalSignName:trimmedVitalSignName },
            { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
          )
          .then((res) => {
            console.log(res);
            if (res.data.message) {
              handleClose();
              formik.resetForm();
              setSubmitVitalSign(false);
              getVitalSignNames(pageNumber);
            }
          }).catch((err:any)=>{
            console.log(err)
            setSubmitVitalSign(false);
            formik.setFieldError('VitalSignName',err.response.data.message)
          });
      }
    },
  });

  return (
    <>
      <div className="m-0 p-0 h-100">
        <div className="col-lg-18 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">General Questions</h4> */}
              <p className="card-description d-flex justify-content-end">
                <button type="button" className="btn btn-primary" onClick={openModal}>
                  <KTIcon iconName="plus" className="fs-2" />
                  Add Vital Sign Name
                </button>
              </p>
              {VitalSignList.length > 0 ? (
                <div>
                  <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive">
                    <table className="table table-striped h-100">
                      <thead>
                        <tr>
                          <th className='fw-bolder'>Vital Sign Name</th>
                          <th className='fw-bolder'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {VitalSignList.map((item:any, index) => (
                          <tr key={index}>
                            <td>{item.VitalSignName ? item.VitalSignName : '-'}</td>
                            <td>
                              <div className="d-flex">
                                <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}>
                                  <i className="fa fa-edit fa-5x fs-2" />
                                </div>
                                <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteVitalSign(item.ID)}>
                                  <i className="fa fa-trash fa-5x fs-2" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="m-auto mt-6">
                    {VitalSignList.length > 0 ? (
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={'paginationbtns'}
                        previousLinkClassName={'prevBtn'}
                        nextLinkClassName={'nextBtn'}
                        disabledClassName={'paginationDisabled'}
                        activeClassName={'paginationActive'}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) :loading ? (
                <span className='indicator-progress text-center' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) :(
                <div className="text-center w-90">
                  <h2 className="mt-9">Records not found</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editVitalSign ? 'Edit' : 'Add'} Vital Sign Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="categoryName">Vital Sign Name</label>
              <input
                type="text"
                className={`form-control ${formik.touched.VitalSignName && formik.errors.VitalSignName ? 'is-invalid' : ''}`}
                id="categoryName"
                name="VitalSignName"
                value={formik.values.VitalSignName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.VitalSignName && formik.errors.VitalSignName && (
                <div className="invalid-feedback">{formik.errors.VitalSignName}</div>
              )}
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={submitVitalSign}>
                {!submitVitalSign ? 'Save' : 'Submitting'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
