import config from 'config'

const AWS = require('aws-sdk')

export function getBucketURL(objectKey) {
  AWS.config.update({
    accessKeyId: config.AWS_KEY,
    secretAccessKey: config.AWS_SECRET_KEY,
    region: config.AWS_REGION,
  })

  const s3 = new AWS.S3()

  const params = {
    Bucket: config.AWS_S3_BUCKET_NAME,
    Key: objectKey,
  }

  // console.log('value------------', )
  return new Promise((resolve, reject) => {
    s3.getSignedUrl('getObject', params, function (err, url) {
      if (err) {
        reject(err)
      } else {
       
        resolve(url)
      }
    })
  })
}
