import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import PackageMasterList from './package-master/PackageMasterList'
// import DiagnosisList from './diagnosis/DiagnosisList'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Package Master',
    path: '/apps/package-master-management/package-masters',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PackageMaster = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='package-masters'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Package Master</PageTitle>
           <PackageMasterList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/package-master-management/package-masters' />} />
  </Routes>
  )
}

export default PackageMaster
