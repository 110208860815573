import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth';
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap';
import { KTIcon } from '../../../../../_metronic/helpers';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import config from 'config'

const validationSchema = Yup.object().shape({
  PackageType: Yup.string().required('PackageType is required'),
  Price: Yup.string().required('Price is required'),
});

export default function PackageMasterList() {
  const { saveAuth, setCurrentUser } = useAuth();
  const TOKEN = authHelper.getAuth();
  const [loading, setLoading] = useState(true)
  const [packageMasterList, setpackageMasterList] = useState([]);

  const [editProfileMaster, seteditProfileMaster] = useState(false);
  const [submitProfileMaster, setSubmitProfileMaster] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [length, setLength] = useState(0);
  let pageCount = Math.ceil(length / pageSize);

  const handleShow = (item: any) => {
    setSubmitProfileMaster(false);
    setShowModal(true);
    seteditProfileMaster(true);
    formik.setValues({ ...item })

  };

  const openModal = () => {
    setSubmitProfileMaster(false);
    setShowModal(true);
  };

  const changePage = ({ selected }: any) => {
    const newPageNumber = selected + 1;
    setPageNumber(newPageNumber);
    getPackageMaster(newPageNumber);
  };
  const formik = useFormik({
    initialValues: {
      ID: 0,
      PackageType: 'monthly',
      Price: '',
      IsPremium: 0,
    },
    validationSchema,
    onSubmit: (values) => {

      setSubmitProfileMaster(true);

      if (!editProfileMaster) {

        axios.post(`${config.SERVER_URL}/packagemasters/addpackagemasters`, { PackageType: formik.values.PackageType, Price: formik.values.Price, IsPremium: formik.values.IsPremium === 0 ? '0' : '1' }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
          console.log(res)
          if (res.data.message) {
            handleClose()
            getPackageMaster(pageNumber)
          }
        })
      } else {
        axios.put(`${config.SERVER_URL}/packagemasters/updatepackagemasters/${formik.values.ID}`, { PackageType: formik.values.PackageType, Price: formik.values.Price, IsPremium: formik.values.IsPremium === 0 ? '0' : '1' }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
          console.log(res)
          if (res.data.message) {
            handleClose()
            getPackageMaster(pageNumber)
          }
        })
      }
    },
  });

  const deletePackageMaster = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to delete this entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(`${config.SERVER_URL}/packagemasters/deletepackagemasters/${id}`, {
                headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
              })
              .then((res) => {
                if (res.data.message) {
                  getPackageMaster(pageNumber);
                }
              });
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  const handleClose = () => {
    setShowModal(false);
    seteditProfileMaster(false);
    setSubmitProfileMaster(false)
    formik.resetForm();
  };

  const getPackageMaster = (pageNumber: any) => {
    setLoading(true)
    try {
      axios
        .get(
          `${config.SERVER_URL}/packagemasters/getallpackagemastersdata?page=${pageNumber}&limit=${pageSize}`,
          { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
        )
        .then((res: any) => {
          if (res.data) {
            setLoading(false)
            if (res.data.packageMasters.length !== 0) {
              setpackageMasterList(res.data.packageMasters);
              setLength(res.data.totalRecords);
            } else {
              if (pageNumber > 1) {
                getPackageMaster(pageNumber - 1);
                setPageNumber(pageNumber - 1);
              }
            }
          } else {
            saveAuth(undefined);
            setCurrentUser(undefined);
          }
        })
        .catch((err) => {
          console.log(err);
          setCurrentUser(undefined);
          saveAuth(undefined);
        });
    } catch (err) {
      console.log(err);
      setCurrentUser(undefined);
      saveAuth(undefined);
    }
  };

  useEffect(() => {
    getPackageMaster(1);
  }, []);
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? 'transparent' : provided.borderColor,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      border: 'none',
      outline: state.isFocused ? 'none' : 'none',
     
    }),
  };
  const options = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'halfyearly', label: 'Half-yearly' },
    { value: 'yearly', label: 'Yearly' },
    { value: 'other', label: 'Other' },
  ];
  return (
    <>
      <div className="m-0 p-0 h-100">
        <div className="col-lg-18 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description d-flex justify-content-end">
                <button type="button" className="btn btn-primary" onClick={openModal}>
                  <KTIcon iconName="plus" className="fs-2" />
                  Add Package Master
                </button>
              </p>
              {packageMasterList.length > 0 ? (
                <div>
                  <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive">
                    <table className="table table-striped h-100">
                      <thead>
                        <tr>
                          <th className='fw-bolder'>PackageType</th>
                          <th className='fw-bolder'>Price</th>
                          <th className='fw-bolder'>Display On Top</th>
                          <th className='fw-bolder'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packageMasterList.map((item: any, index) => (
                          <tr key={index}>
                            <td>{item.PackageType ? item.PackageType : '-'}</td>
                            <td>{item.Price ? item.Price : '-'}</td>
                            <td>{item.IsPremium === 1 ? 'Yes' : 'No'}</td>
                            <td>
                              <div className="d-flex">
                                <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}>
                                  <i className="fa fa-edit fa-5x fs-2" />
                                </div>
                                <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deletePackageMaster(item.ID)}>
                                  <i className="fa fa-trash fa-5x fs-2" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="m-auto mt-6">
                    {packageMasterList.length > 0 && (
                      <ReactPaginate
                        previousLabel="Prev"
                        nextLabel="Next"
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName="paginationbtns"
                        previousLinkClassName="prevBtn"
                        nextLinkClassName="nextBtn"
                        disabledClassName="paginationDisabled"
                        activeClassName="paginationActive"
                      />
                    )}
                  </div>
                </div>
              ) : loading ? (
                <span className='indicator-progress text-center' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <div className="text-center w-90">
                  <h2 className="mt-9">Records not found</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editProfileMaster ? 'Edit Package Master' : 'Add Package Master'}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="row">
              <label className="col-lg-4 col-form-label fw-bold fs-6"></label>
            </div>


            <div className="form-group mb-5 mt-5">
              <label htmlFor="packageType">Package Type</label>
              <Select
                id="packageType"
                name="PackageType"
                className='form-control p-0'
                value={options.find((option) => option.value === formik.values.PackageType)}
                options={options}
                styles={customStyles}
                onChange={(selectedOption) => formik.setFieldValue('PackageType', selectedOption?.value || '')}
                placeholder="Select Package type"
              />
            </div>
            <div className="form-group mb-5 mt-5">
              <label htmlFor="price">Price</label>
              <input
                type="text"
                className={`form-control ${formik.touched.Price && formik.errors.Price ? 'is-invalid' : ''}`}
                id="price"
                name="Price"
                value={formik.values.Price}
                onChange={(e) => {
                  const onlyNumbers = e.target.value.replace(/[^0-9.]/g, '');
                  formik.handleChange({
                    target: {
                      id: 'price',
                      name: 'Price',
                      value: onlyNumbers,
                    },
                  });
                }}
                onBlur={formik.handleBlur}
              />

              {formik.touched.Price && formik.errors.Price && (
                <div className="invalid-feedback">{formik.errors.Price}</div>
              )}
            </div>
            <div className="form-check mb-5 mt-7">
              <label className="form-check-label" htmlFor="isPremium">
              Display On Top
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                id="isPremium"
                name="IsPremium"
                checked={formik.values.IsPremium === 1}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  formik.handleChange({
                    target: {
                      id: 'isPremium',
                      name: 'IsPremium',
                      value: isChecked ? 1 : 0,
                    },
                  });
                }}
                onBlur={formik.handleBlur}
              />

            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" disabled={submitProfileMaster} type="submit">
              {!submitProfileMaster ? 'Save' : 'Submitting'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

    </>
  );
}
