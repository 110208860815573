import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from 'config'

const validationSchema = Yup.object().shape({
    Name: Yup.string().transform((value) => value.trim()).required('Name is required'),
});

export default function BodyPartList() {
    const { saveAuth, setCurrentUser } = useAuth()
    const TOKEN = authHelper.getAuth()
    const [loading, setLoading] = useState(true)
    const [BodyPartList, setBodyPartList] = useState([])
    const [editBodyPart, seteditBodyPart] = useState(false);
    const [submitBodyPart, setsubmitBodyPart] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let [pagenumber, setPagenumber] = useState(1)
    const pagesize = 10
    const [length, setLength] = useState(0)
    let pageCount = Math.ceil(length / pagesize)

    const handleShow = (item: any) => {
        setsubmitBodyPart(false)
        setShowModal(true);
        seteditBodyPart(true);
        formik.setValues({ ...item })
    };
    const openModal = () => {
        setsubmitBodyPart(false)
        setShowModal(true);
    };
    const changePage = ({ selected }: any) => {
        setPagenumber(selected + 1)
        pagenumber = selected + 1
        getBodyparts(pagenumber)
    }


    const deleteBodyPart = (id: any) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'you want to delete this entry ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.delete(`${config.SERVER_URL}/chatgpt/deletebodyparts/${id}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                            if (res.data.message) {
                                getBodyparts(pagenumber)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });


    }

    const handleClose = () => {
        setsubmitBodyPart(false)
        setShowModal(false);
        seteditBodyPart(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            ID: null,
            Name: '',
        },
        validationSchema,
        onSubmit: (values) => {
            setsubmitBodyPart(true)
            const trimmedName = values.Name.trim();

            // Check if the DiagnosisName is empty after removing spaces
            if (trimmedName === '') {
                formik.setFieldError('Name', 'Name is required');
                formik.setFieldValue('Name', '')
                return;
            }
            if (!editBodyPart) {

                axios.post(`${config.SERVER_URL}/chatgpt/addbodyparts`, { Name: trimmedName }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res)
                    if (res.data.message) {
                        handleClose()
                        getBodyparts(pagenumber)
                    }
                })
            } else {
                axios.put(`${config.SERVER_URL}/chatgpt/updatebodyparts/${values.ID}`, { Name: trimmedName }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res)
                    if (res.data.message) {
                        handleClose()
                        getBodyparts(pagenumber)
                    }
                })
            }
        },
    });


    const getBodyparts = (pagenumber: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/chatgpt/getallbodyparts?page=${pagenumber}&limit=${pagesize}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    if (res.data.bodyParts.length !== 0) {
                        setBodyPartList(res.data.bodyParts)
                        setLength(res.data.totalRecords)
                    } else {
                        if (pagenumber > 1) {
                            getBodyparts(pagenumber - 1)
                            setPagenumber(pagenumber - 1)
                        }
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }

    useEffect(() => {
        getBodyparts(1)
    }, [])

    return (<>
        <div className="m-0 p-0 h-100">
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">General Questions</h4> */}
                        <p className="card-description d-flex justify-content-end">
                            <button type='button' className='btn btn-primary' onClick={openModal}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Body Part
                            </button>
                        </p>
                        {BodyPartList.length > 0 ? <div> <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive" >
                            <table className="table table-striped h-100">
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Name</th>
                                        <th className='fw-bolder'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        BodyPartList.map((item: any, index) => (
                                            <tr key={index}>


                                                <td>
                                                    {item.Name ? item.Name : '-'}
                                                </td>

                                                <td>
                                                    <div className='d-flex '>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}  ><i className="fa fa-edit fa-5x fs-2" /></div>

                                                        <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteBodyPart(item.ID)}> <i className="fa fa-trash fa-5x fs-2" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className='m-auto mt-6'>
                                {BodyPartList.length > 0 ? <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationbtns"}
                                    previousLinkClassName={"prevBtn"}
                                    nextLinkClassName={"nextBtn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> : ""}
                            </div>
                        </div> : loading ? (
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        ) : <div className='text-center w-90'><h2 className='mt-9'>Records not found</h2></div>
                        } </div></div></div></div>
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{editBodyPart ? "Edit Body Part" : "Add Body Part"}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className='row '>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Name">Name</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.Name && formik.errors.Name ? 'is-invalid' : ''}`}
                            id="Name"
                            name="Name"
                            value={formik.values.Name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                        />
                        {formik.touched.Name && formik.errors.Name && (
                            <div className="invalid-feedback">{formik.errors.Name}</div>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitBodyPart} type='submit'>
                        {!submitBodyPart ? 'Save' : 'Submiting'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
    )
}
