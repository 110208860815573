import { Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import AhPartnerServiceList from './ah-partner-service/AhPartnerServiceList'
import { AddEditAhPartnerService } from './ah-partner-service/AddEditAhPartnerService'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'AH Partner Service',
    path: '/apps/ah-partner-service-management/services',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AhPartnerService = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='services'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Ah Partner Services</PageTitle>
              <AhPartnerServiceList/>
            </>
          }
        />
        <Route
          path='add-service'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Ah Partner Service</PageTitle>
            <AddEditAhPartnerService/>
            </>
          }
          />
        <Route
          path='edit-service/:serviceId'
          element={
              <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Ah Partner Service</PageTitle>
              <AddEditAhPartnerService/>
            

            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/apps/ah-partner-service-management/services' />} />
    </Routes>
  )
}

export default AhPartnerService
