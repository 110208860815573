import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
// import FamousPeopleCategoryList from '../famous-people-category-management/category/FamousPeopleCategoryList';
import FamousPeopleList from './famous-people/famous-people/FamousPeopleList';
import { AddEditFamousPeople } from './famous-people/famous-people/AddEditFamousPeople';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Famouse Peoples',
    path: '/apps/famous-peoples-management/famous-peoples/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Famouse Peoples',
    path: '/apps/famous-peoples-management/famous-peoples',
    isSeparator: true,
    isActive: false,
  },
]

const FamousPeople = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='famous-peoples'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Famous Peoples</PageTitle>
            <FamousPeopleList/>
          </>
        }
      />
      <Route
        path='famous-peoples/add-people'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Famous Peoples</PageTitle>
            <AddEditFamousPeople/>
          </>
        }
      />
      <Route
        path='famous-peoples/edit-people/:peopleId'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Famous Peoples</PageTitle>
            <AddEditFamousPeople/>
          </>
        }
      />
    </Route>
   
    <Route index element={<Navigate to='/apps/famous-peoples-management/famous-peoples' />} />
  </Routes>
  )
}

export default FamousPeople
