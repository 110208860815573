import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import SymptomList from './symptoms/SymptomsList'




const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Symptoms',
    path: '/apps/symptoms-management/symptoms',
    isSeparator: false,
    isActive: false,
  }
]

const Symptoms = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='symptoms'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Symptoms</PageTitle>
          <SymptomList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/symptoms-management/symptoms' />} />
  </Routes>
  )
}

export default Symptoms
