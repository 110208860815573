import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import clsx from 'clsx';
import config from 'config'

const validationSchema = Yup.object().shape({
    Question: Yup.string().transform((value) => value.trim()).required('Question is required'),
    QuestionType: Yup.string().required('Question Type is required'),
    DiagnosisID: Yup.number().notOneOf([0], 'Diagnosis is required'),
    ThumbnailUrl: Yup.string().transform((value) => value.trim()).url('Invalid URL format'),
    RedirectUrl: Yup.string().transform((value) => value.trim()).url('Invalid URL format'),
});

export default function QuestionMasterList() {
    const { saveAuth, setCurrentUser } = useAuth()
    const TOKEN = authHelper.getAuth()
    const [loading, setLoading] = useState(true)
    const [QuestionMasterList, setQuestionMasterList] = useState([{ ID: '', Question: '' }])
    const [diagnosisOptions, setdiagnosisOptions] = useState([{ ID: '', DiagnosisName: '' }]);
    const [editQuestionMaster, seteditQuestionMaster] = useState(false);
    const [submitQuestionMaster, setsubmitQuestionMaster] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let [pagenumber, setPagenumber] = useState(1)
    const pagesize = 10
    const [length, setLength] = useState(0)
    let pageCount = Math.ceil(length / pagesize)

    const handleShow = (item: any) => {
        setsubmitQuestionMaster(false)
        setShowModal(true);
        seteditQuestionMaster(true);
        formik.setValues({ ...item })
    };
    const openModal = () => {
        setsubmitQuestionMaster(false)
        setShowModal(true);
    };
    const changePage = ({ selected }: any) => {
        setPagenumber(selected + 1)
        pagenumber = selected + 1
        getQuestionMaster(pagenumber)
    }


    const deleteAnswerMaster = (id: any) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'you want to delete this entry ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.delete(`${config.SERVER_URL}/diagnosis/deletequestionmasters/${id}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                            if (res.data.message) {
                                getQuestionMaster(pagenumber)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });


    }

    const handleClose = () => {
        setShowModal(false);
        seteditQuestionMaster(false);
        setsubmitQuestionMaster(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            ID: '',
            Question: '',
            QuestionType: '',
            DiagnosisID: 0,
            ThumbnailUrl: '',
            RedirectUrl: '',
        },
        validationSchema,
        onSubmit: (values) => {
            setsubmitQuestionMaster(true);

            // Trim the values before sending the request
            const trimmedValues = {
                ...values,
                Question: values.Question.trim(),
                QuestionType: values.QuestionType.trim(),
                ThumbnailUrl: values.ThumbnailUrl.trim(),
                RedirectUrl: values.RedirectUrl.trim(),
            };

            if (!editQuestionMaster) {
                axios.post(`${config.SERVER_URL}/diagnosis/addquestionmasters`, trimmedValues, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res);
                    if (res.data.message) {
                        handleClose();
                        getQuestionMaster(pagenumber);
                    }
                });
            } else {
                axios.put(`${config.SERVER_URL}/diagnosis/updatequestionmasters/${values.ID}`, trimmedValues, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res);
                    if (res.data.message) {
                        handleClose();
                        getQuestionMaster(pagenumber);
                    }
                });
            }
        },
    });
    const getQuestionMaster = (pagenumber: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/diagnosis/getallquestionmasterswithpagination?page=${pagenumber}&limit=${pagesize}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    if (res.data.questionMasters.length !== 0) {
                        setQuestionMasterList(res.data.questionMasters)
                        setLength(res.data.totalRecords)
                    } else {
                        if (pagenumber > 1) {
                            getQuestionMaster(pagenumber - 1)
                            setPagenumber(pagenumber - 1)
                        }
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }
    const getDiagnosys = () => {
        axios
            .get(`${config.SERVER_URL}/diagnosis/all`, {
                headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
            })
            .then((res) => {
                console.log(res)
                if (res.data.diagnosisList.length > 0) {
                    setdiagnosisOptions(res.data.diagnosisList)
                }
            })
            .catch((err) => {
                saveAuth(undefined);
                setCurrentUser(undefined);
            });
    };

    useEffect(() => {
        getQuestionMaster(1)
        getDiagnosys()
    }, [])
    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: state.isFocused ? 'transparent' : provided.borderColor,
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            border: 'none',
            outline: state.isFocused ? 'none' : 'none',

        }),
    };
    const options = [
        { value: 'single', label: 'Single' },
        { value: 'multiple', label: 'Multiple' },
    ];
    return (<>
        <div className="m-0 p-0 h-100">
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">General Questions</h4> */}
                        <p className="card-description d-flex justify-content-end">
                            <button type='button' className='btn btn-primary' onClick={openModal}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Question master
                            </button>
                        </p>
                        {QuestionMasterList.length > 0 ? <div> <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive" >
                            <table className="table table-striped h-100">
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Question</th>
                                        <th className='fw-bolder'>Question Type</th>
                                        <th className='fw-bolder'>Diagnosis Name</th>
                                        <th className='fw-bolder'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        QuestionMasterList.map((item: any, index) => (
                                            <tr key={index}>

                                                <td>
                                                    {item.Question ? item.Question : '-'}
                                                </td>
                                                <td>
                                                    {item.QuestionType ? item.QuestionType : '-'}
                                                </td>
                                                <td>
                                                    {item.DiagnosisName ? item.DiagnosisName : '-'}
                                                </td>

                                                <td>
                                                    <div className='d-flex '>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}  ><i className="fa fa-edit fa-5x fs-2" /></div>

                                                        <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteAnswerMaster(item.ID)}> <i className="fa fa-trash fa-5x fs-2" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className='m-auto mt-6'>
                                {QuestionMasterList.length > 0 ? <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationbtns"}
                                    previousLinkClassName={"prevBtn"}
                                    nextLinkClassName={"nextBtn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> : ""}
                            </div>
                        </div> : loading ? (
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        ) : <div className='text-center w-90'><h2 className='mt-9'>Records not found</h2></div>
                        } </div></div></div></div>
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{editQuestionMaster ? 'Edit Question Master' : 'Add Question Master'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="form-group mb-5 mt-5" >
                        <label htmlFor="DiagnosisID">
                            Diagnosis Name
                        </label>
                        <Select
                            options={diagnosisOptions}
                            styles={customStyles}
                            getOptionLabel={(option) => option.DiagnosisName}
                            getOptionValue={(option) => option.ID}
                            classNamePrefix="select diagnosis"
                            className={clsx('form-control bg-transparent p-0', {
                                'is-invalid': formik.touched.DiagnosisID && formik.errors.DiagnosisID,
                            })}
                            value={diagnosisOptions.find((option: any) => option.ID === formik.values.DiagnosisID)}
                            onChange={(selectedOption) => formik.setFieldValue('DiagnosisID', selectedOption?.ID || '')}
                            placeholder="Select Diagnosis"
                            onBlur={formik.handleBlur('DiagnosisID')}
                        />

                        <div className="invalid-feedback">{formik.errors.DiagnosisID}</div>
                    </div>

                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="Question">Question</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.Question && formik.errors.Question ? 'is-invalid' : ''}`}
                            id="Question"
                            name="Question"
                            value={formik.values.Question}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.Question && formik.errors.Question && (
                            <div className="invalid-feedback">{formik.errors.Question}</div>
                        )}
                    </div>

                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="QuestionType">Question Type</label>
                        <Select
                            id="QuestionType"
                            name="QuestionType"
                            className={clsx('form-control bg-transparent p-0', {
                                'is-invalid': formik.touched.QuestionType && formik.errors.QuestionType,
                            })}
                            value={options.find((option) => option.value === formik.values.QuestionType)}
                            options={options}
                            styles={customStyles}
                            onChange={(selectedOption) => formik.setFieldValue('QuestionType', selectedOption?.value || '')}
                            placeholder="Select Question type"
                        />

                        <div className="invalid-feedback">{formik.errors.QuestionType}</div>

                    </div>


                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="ThumbnailUrl">Thumbnail Url</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.ThumbnailUrl && formik.errors.ThumbnailUrl ? 'is-invalid' : ''}`}
                            id="ThumbnailUrl"
                            name="ThumbnailUrl"
                            value={formik.values.ThumbnailUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.ThumbnailUrl && formik.errors.ThumbnailUrl && (
                            <div className="invalid-feedback">{formik.errors.ThumbnailUrl}</div>
                        )}
                    </div>

                    <div className="form-group mb-5 mt-5">
                        <label htmlFor="RedirectUrl">Redirect Url</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.RedirectUrl && formik.errors.RedirectUrl ? 'is-invalid' : ''}`}
                            id="RedirectUrl"
                            name="RedirectUrl"
                            value={formik.values.RedirectUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.RedirectUrl && formik.errors.RedirectUrl && (
                            <div className="invalid-feedback">{formik.errors.RedirectUrl}</div>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitQuestionMaster} type="submit">
                        {!submitQuestionMaster ? 'Save' : 'Submitting'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
    )
}
