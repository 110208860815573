import { Route, Routes, Outlet, Navigate, } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import FamousPeopleCategoryList from './category/FamousPeopleCategoryList'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Famous peoples category',
    path: '/apps/famous-peoples-category-management/famous-peoples/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FamousPeopleCategory = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='famous-peoples/categories'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Famous Peoples category</PageTitle>
            <FamousPeopleCategoryList />
          </>
        }
      />
    </Route>
   
    <Route index element={<Navigate to='/apps/famous-peoples-category-management/famous-peoples' />} />
  </Routes>
  )
}

export default FamousPeopleCategory
