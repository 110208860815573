import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap'
// import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from 'config'


const validationSchema = Yup.object().shape({
    Coins: Yup.number().required('Coins is required'),
});

export default function RewardMasterList() {
    const { saveAuth, setCurrentUser } = useAuth()
    const TOKEN = authHelper.getAuth()
    const [loading, setLoading] = useState(true)
    const [RewardList, setRewardList] = useState([])
    const [editReward, seteditReward] = useState(false);
    const [submitReward, setsubmitReward] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let [pagenumber, setPagenumber] = useState(1)
    const pagesize = 10
    const [length, setLength] = useState(0)
    let pageCount = Math.ceil(length / pagesize)

    const handleShow = (item: any) => {
        setsubmitReward(false)
        setShowModal(true);
        seteditReward(true);
        formik.setValues({ ...item, IsFreezeReward: item.IsFreezeReward === 1 ? true : false })
    };

    const changePage = ({ selected }: any) => {
        setPagenumber(selected + 1)
        pagenumber = selected + 1
        getRewards(pagenumber)
    }


    const handleClose = () => {
        setsubmitReward(false);
        setShowModal(false);
        seteditReward(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            ID: null,
            AID: '',
            Coins: 0,
            Email: '',
            IsFreezeReward: false,
        },
        validationSchema,
        onSubmit: (values) => {
            setsubmitReward(true)
            axios.post(`${config.SERVER_URL}/rewardmaster/addupdaterewardmaster`, { AID: values.AID, Coins: values.Coins, IsFreezeReward: values.IsFreezeReward === true ? '1' : '0' }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                console.log(res)
                if (res.data.message) {
                    handleClose()
                    getRewards(pagenumber)
                }
            })

        },
    });


    const getRewards = (pagenumber: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/rewardmaster/getallrewardmasterwithpagination?page=${pagenumber}&limit=${pagesize}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    if (res.data.Rewards.length !== 0) {
                        setRewardList(res.data.Rewards)
                        setLength(res.data.totalRecords)
                    } else {
                        if (pagenumber > 1) {
                            getRewards(pagenumber - 1)
                            setPagenumber(pagenumber - 1)
                        }
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }

    useEffect(() => {
        getRewards(1)
    }, [])

    return (<>
        <div className="m-0 p-0 h-100">
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">General Questions</h4> */}
                        {/* <p className="card-description d-flex justify-content-end">
                            <button type='button' className='btn btn-primary' onClick={openModal}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Reward
                            </button>
                        </p> */}
                        {RewardList.length > 0 ? <div> <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive" >
                            <table className="table table-striped h-100">
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Email</th>
                                        <th className='fw-bolder'>Coins</th>
                                        <th className='fw-bolder'>IsFreezeReward</th>
                                        <th className='fw-bolder'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        RewardList.map((item: any, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.Email ? item.Email : '-'}
                                                </td>
                                                <td>
                                                    {item.Coins ? item.Coins : '-'}
                                                </td>
                                                <td>
                                                    {item.IsFreezeReward === 1 ? 'Yes' : 'No'}
                                                </td>

                                                <td>
                                                    <div className='d-flex '>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}  ><i className="fa fa-edit fa-5x fs-2" /></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className='m-auto mt-6'>
                                {RewardList.length > 0 ? <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationbtns"}
                                    previousLinkClassName={"prevBtn"}
                                    nextLinkClassName={"nextBtn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> : ""}
                            </div>
                        </div> : loading ? (
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        ) : <div className='text-center w-90'><h2 className='mt-9'>Records not found</h2></div>
                        } </div></div></div></div>
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Reward</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className='row '>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="Email">Email</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.Email && formik.errors.Email ? 'is-invalid' : ''}`}
                            id="Email"
                            readOnly
                            name="Email"
                            value={formik.values.Email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                        />

                    </div>
                    <div className="form-group">
                        <label htmlFor="Coins">Coins</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.Coins && formik.errors.Coins ? 'is-invalid' : ''}`}
                            id="Coins"
                            name="Coins"
                            value={formik.values.Coins}
                            onChange={(e) => {
                                const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
                                formik.handleChange({
                                    target: {
                                        id: 'Coins',
                                        name: 'Coins',
                                        value: onlyNumbers,
                                    },
                                });
                            }}
                            onBlur={formik.handleBlur}

                        />
                        {formik.touched.Coins && formik.errors.Coins && (
                            <div className="invalid-feedback">{formik.errors.Coins}</div>
                        )}
                    </div>
                    <div className="form-check mb-5 mt-7">
                        <label className="form-check-label" htmlFor="IsFreezeReward">
                            IsFreezeReward
                        </label>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="IsFreezeReward"
                            name="IsFreezeReward"
                            checked={formik.values.IsFreezeReward}
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                formik.handleChange({
                                    target: {
                                        id: 'IsFreezeReward',
                                        name: 'IsFreezeReward',
                                        value: isChecked ? 1 : 0,
                                    },
                                });
                            }}
                            onBlur={formik.handleBlur}
                        />

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitReward} type='submit'>
                        {!submitReward ? 'Save' : 'Submiting'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
    )
}
