import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import QuestionMasterList from './question-master/QuestionMasterList'



const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Question Master',
    path: '/apps/question-master-management/question-masters',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const QuestionMaster = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='question-masters'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Question Master</PageTitle>
          <QuestionMasterList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/question-master-management/question-masters' />} />
  </Routes>
  )
}

export default QuestionMaster
