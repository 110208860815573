import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers';
// import { Modal, Button } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css'
import config from 'config'

export default function AhPartnerList() {
    const navigate = useNavigate();
    const { saveAuth, setCurrentUser } = useAuth()
    const TOKEN = authHelper.getAuth()
    const [ahPartnersList, setahPartnersList] = useState([])
    const [loading, setLoading] = useState(true)
    let [pagenumber, setPagenumber] = useState(1)
    const pagesize = 10
    const [length, setLength] = useState(0)
    let pageCount = Math.ceil(length / pagesize)

    const AddPartner = () => {
        navigate('/apps/ah-partner-management/ah-partner/add-partner');
    };
    const EditPartner = (id: string) => {
        navigate('/apps/ah-partner-management/ah-partner/edit-partner/' + id);
    };
    const changePage = ({ selected }: any) => {
        setPagenumber(selected + 1)
        pagenumber = selected + 1
        getahPartnersList(pagenumber)
    }

    const deleteAhPartner = (id: any) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'you want to delete this entry ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.delete(`${config.SERVER_URL}/ahpartner/deleteahpartner/${id}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                            if (res.data.message) {
                                getahPartnersList(pagenumber)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });


    }

    const getahPartnersList = (pagenumber: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/ahpartner/getallahpartnerswithpagination?page=${pagenumber}&limit=${pagesize}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                console.log(res)
                if (res.data) {
                    setLoading(false)
                    if (res.data.ahPartners.length !== 0) {
                        setahPartnersList(res.data.ahPartners)
                        setLength(res.data.totalRecords)
                    } else {
                        if (pagenumber > 1) {
                            getahPartnersList(pagenumber - 1)
                            setPagenumber(pagenumber - 1)
                        }
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }
    useEffect(() => {
        getahPartnersList(1)
    }, [])

    return (
        <div className="m-0 p-0 h-100">
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">General Questions</h4> */}
                        <p className="card-description d-flex justify-content-end">
                            <button type='button' className='btn btn-primary' onClick={AddPartner}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Ah Partner
                            </button>
                        </p>
                        {ahPartnersList.length > 0 ? <div> <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive" >
                            <table className="table table-striped h-100">
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>PartnerName</th>
                                        <th className='fw-bolder'>CategoryName</th>
                                        <th className='fw-bolder'>City</th>
                                        <th className='fw-bolder'>State</th>
                                        <th className='fw-bolder'>Country</th>
                                        <th className='fw-bolder'>Pincode</th>
                                        <th className='fw-bolder'>IsOnline</th>
                                        <th className='fw-bolder'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        ahPartnersList.map((item: any, index) => (
                                            <tr key={index}>

                                                <td>
                                                    {item.PartnerName ? item.PartnerName : '-'}
                                                </td>
                                                <td>
                                                    {item.CategoryName ? item.CategoryName : '-'}
                                                </td>
                                                <td>
                                                    {item.City ? item.City : '-'}
                                                </td>
                                                <td>
                                                    {item.State ? item.State : '-'}
                                                </td>
                                                <td>
                                                    {item.Country ? item.Country : '-'}
                                                </td>
                                                <td>
                                                    {item.Pincode ? item.Pincode : '-'}
                                                </td>
                                                <td>
                                                    {item.IsOnline === 1 ? 'Yes' : 'No'}
                                                </td>

                                                <td>
                                                    <div className='d-flex '>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => EditPartner(item.ID)}  ><i className="fa fa-edit fa-5x fs-2" /></div>

                                                        <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteAhPartner(item.ID)}> <i className="fa fa-trash fa-5x fs-2" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className='m-auto mt-6'>
                                {ahPartnersList.length > 0 ? <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationbtns"}
                                    previousLinkClassName={"prevBtn"}
                                    nextLinkClassName={"nextBtn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> : ""}
                            </div>
                        </div> : loading ? (
                            <span className='indicator-progress text-center' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        ) : <div className='text-center w-90'><h2 className='mt-9'>Records not found</h2></div>
                        } </div></div></div></div>
    )
}
