import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
// import AhPartenrCategoryList from '../ah-partner-category-management/ah-partner-category/AhPartnerCategoryList'
import AhPartnerList from './ah-partner/AhPartnerList'
import { AddEditAhPartner } from './ah-partner/AddEditAhPartner'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'AH Partner',
    path: '/apps/ah-partner-management/ah-partners',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Famouse Peoples',
    path: '/apps/ah-partner-management/ah-partners',
    isSeparator: true,
    isActive: false,
  },
]

const AhPartner = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='ah-partners'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Ah Partner</PageTitle>
              <AhPartnerList />
            </>
          }
        />
        <Route
          path='ah-partner/add-partner'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Ah Partner</PageTitle>
              <AddEditAhPartner />
            </>
          }
        />
        <Route
          path='ah-partner/edit-partner/:partnerId'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Ah Partner</PageTitle>
              <AddEditAhPartner />

            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='/apps/ah-partner-management/ah-partners' />} />
    </Routes>
  )
}

export default AhPartner
