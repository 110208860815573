import React, {useState, useEffect, useMemo} from 'react'
import axios from 'axios'
import config from 'config'
import {useAuth} from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers'
import '../../css/dashboardStyles.css'
import {Pie} from 'react-chartjs-2'
import 'chart.js/auto'
import {log} from 'console'

interface GenderDistribution {
  Gender: string
  count: number
}

interface CityDistribution {
  City: string
  CityCount: number
}

interface AgeBrackets {
  [key: string]: number
}

interface PathReportType {
  PathReportType: string
  total: number
}

interface HealthEpisodeDiagnosis {
  DiagnosisName: string
  total: number
}

interface HealthDataStats {
  totalPathReports: number
  ratioPathReportsPerAHMember: number
  pathReportsByType: PathReportType[]
  totalHealthEpisodes: number
  healthEpisodesByDiagnosis: HealthEpisodeDiagnosis[]
}

interface StatisticsData {
  Total_Account_Creation_Attempts: number
  Incomplete_Accounts: number
  Complete_Accounts: number
  Number_of_logins: number
  Total_Secondary_Users: number
  Total_AH_Members: number
  Gender_Distribution: GenderDistribution[]
  Age_Brackets: AgeBrackets
  City_Distribution: CityDistribution[]
  HealthDataStats?: HealthDataStats
}

interface HealthEpisodeDiagnosis {
  DiagnosisName: string
  total: number
}

const DashboardStats = () => {
  const [timeFrame, setTimeFrame] = useState<string>('inf')
  const [statistics, setStatistics] = useState<StatisticsData | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const citiesPerPage: number = 10
  const {saveAuth, setCurrentUser, logout} = useAuth()
  const TOKEN = authHelper.getAuth()

  useEffect(() => {
    const fetchStatistics = async () => {
      setLoading(true)
      setError(null)

      try {
        const statsResponse = await axios.get<StatisticsData>(
          `${config.SERVER_URL}/userExperienceManagement/getStatistics/${timeFrame}`,
          {
            headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
          }
        )

        const healthDataResponse = await axios.get<HealthDataStats>(
          `${config.SERVER_URL}/userExperienceManagement/healthDataStats/${timeFrame}`,
          {
            headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
          }
        )

        setStatistics({
          ...statsResponse.data,
          HealthDataStats: healthDataResponse.data,
        })
      } catch (error) {
        console.error('Error fetching data:', error)
        setError('Failed to load data.')
        logout()
      }

      setLoading(false)
    }

    fetchStatistics()
  }, [timeFrame])

  const handleTimeFrameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeFrame(event.target.value)
    setCurrentPage(0)
  }

  const renderKeyValuePairs = () => {
    if (!statistics || !statistics.HealthDataStats) return null
    return (
      <div className='stats-container'>
        <ul className='stats-list'>
          <li>
            <strong>Total Account Creation Attempts:</strong>{' '}
            {statistics.Total_Account_Creation_Attempts}
          </li>
          <li>
            <strong>New Incomplete Primary Accounts:</strong> {statistics.Incomplete_Accounts}
          </li>
          <li>
            <strong>Total New Primary Accounts Count:</strong> {statistics.Complete_Accounts}
          </li>
          <li>
            <strong>Total New Secondary Accounts Count:</strong> {statistics.Total_Secondary_Users}
          </li>
          <li>
            <strong>Total New AH Member Accounts Count:</strong> {statistics.Total_AH_Members}
          </li>
          <li>
            <strong>Number of Users Logged-in During The Period:</strong>{' '}
            {statistics.Number_of_logins}
          </li>
          <li>
            <strong>Total Number of New Reports Uploaded:</strong>{' '}
            {statistics.HealthDataStats.totalPathReports}
          </li>
          <li>
            <strong>Ratio of New Path Reports per New Member:</strong>{' '}
            {statistics.HealthDataStats.ratioPathReportsPerAHMember.toFixed(2)}
          </li>
        </ul>
      </div>
    )
  }

  const renderGenderDistribution = () => {
    if (!statistics?.Gender_Distribution) return null

    type GenderColors = {
      [key: string]: string
    }

    const genderColors: GenderColors = {
      Male: '#36A2EB', // Blue
      Female: '#FF6384', // Pink
      Diverse: '#FFCE56', // Yellow
    }

    // Prepare data for the pie chart
    const pieData = {
      datasets: [
        {
          data: statistics.Gender_Distribution.map((d) => d.count),
          backgroundColor: statistics.Gender_Distribution.map(
            (d) => genderColors[d.Gender] || '#999'
          ), // Fallback color for Gender
          hoverBackgroundColor: statistics.Gender_Distribution.map(
            (d) => genderColors[d.Gender] || '#999'
          ), // Fallback color for Gender
        },
      ],
      labels: statistics.Gender_Distribution.map((d) => d.Gender),
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom' as const,
        },
      },
    }

    return (
      <div className='col-lg-6 grid-margin stretch-card'>
        <div className='card'>
          <div className='card-header'>
            <h2>Gender Distribution of New Accounts</h2>
          </div>
          <div className='card-body d-flex'>
            <div className='table-responsive flex-grow-1'>
              <table className='table table-striped'>
                <thead>
                  <tr style={{fontWeight: 'bold', fontSize: '1.1em'}}>
                    <th>Gender</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {statistics.Gender_Distribution.map(({Gender, count}) => (
                    <tr key={Gender}>
                      <td>{Gender}</td>
                      <td>{count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='chart-container flex-grow-1'>
              <Pie data={pieData} options={options} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderAgeBrackets = () => {
    if (!statistics?.Age_Brackets) return null

    // Prepare data for the pie chart
    const ageLabels = Object.keys(statistics.Age_Brackets)
    const ageCounts = Object.values(statistics.Age_Brackets)

    const pieData = {
      labels: ageLabels,
      datasets: [
        {
          data: ageCounts,
          backgroundColor: [
            '#FF595E', // Red
            '#FFCA3A', // Orange
            '#8AC926', // Lime Green
            '#1982C4', // Blue
            '#6A4C93', // Indigo
            '#FF9F2E', // Orange (Slightly different shade)
            '#FFD56A', // Light Orange
            '#FF61A6', // Pink
            '#D3F8E2', // Light Green
            '#B2EF9B', // Lighter Green
            '#EE6055',
          ],
          hoverBackgroundColor: [
            '#FF595E', // Red
            '#FFCA3A', // Orange
            '#8AC926', // Lime Green
            '#1982C4', // Blue
            '#6A4C93', // Indigo
            '#FF9F2E', // Orange (Slightly different shade)
            '#FFD56A', // Light Orange
            '#FF61A6', // Pink
            '#D3F8E2', // Light Green
            '#B2EF9B', // Lighter Green
            '#EE6055', // Soft Red
          ],
        },
      ],
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom' as const,
        },
      },
    }

    return (
      <div className='col-lg-6 grid-margin stretch-card'>
        <div className='card'>
          <div className='card-header'>
            <h2>Age Distribution of New Accounts</h2>
          </div>
          <div className='card-body d-flex'>
            <div className='table-responsive' style={{flex: 2, minWidth: 0}}>
              <table className='table table-striped'>
                <thead>
                  <tr style={{fontWeight: 'bold', fontSize: '1.1em'}}>
                    <th>Age Range</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {ageLabels.map((ageRange, index) => (
                    <tr key={ageRange}>
                      <td>{ageRange}</td>
                      <td>{ageCounts[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='chart-container' style={{flex: 3, minWidth: 0}}>
              <Pie data={pieData} options={options} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderCityDistribution = () => {
    if (!statistics?.City_Distribution) return null
    const lastIndex = currentPage * citiesPerPage + citiesPerPage
    const firstIndex = lastIndex - citiesPerPage
    const currentCities = statistics.City_Distribution.slice(firstIndex, lastIndex)

    return (
      <div className='col-lg-6 grid-margin stretch-card'>
        <div className='card'>
          <div className='card-header'>
            <h2>City Distribution of New Accounts</h2>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr style={{fontWeight: 'bold', fontSize: '1.1em'}}>
                    <th>City</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCities.map(({City, CityCount}) => (
                    <tr key={City}>
                      <td>{City}</td>
                      <td>{CityCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='card-footer'>
            <div className='pagination-controls'>
              <button
                className='page-button'
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 0}
              >
                Previous
              </button>
              <button
                className='page-button'
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={lastIndex >= statistics.City_Distribution.length}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderPathReportsByType = () => {
    if (!statistics?.HealthDataStats?.pathReportsByType) return null

    const pieData = {
      labels: statistics.HealthDataStats.pathReportsByType.map((d) => d.PathReportType),
      datasets: [
        {
          data: statistics.HealthDataStats.pathReportsByType.map((d) => d.total),
          backgroundColor: [
            '#FF595E', // Red
            '#FFCA3A', // Orange
            '#8AC926', // Lime Green
            '#1982C4', // Blue
            '#6A4C93', // Indigo
            '#FF9F2E', // Orange (Slightly different shade)
            '#FFD56A', // Light Orange
            '#FF61A6', // Pink
            '#D3F8E2', // Light Green
            '#B2EF9B', // Lighter Green
            '#EE6055', // Soft Red
          ],
          hoverBackgroundColor: [
            '#FF595E', // Red
            '#FFCA3A', // Orange
            '#8AC926', // Lime Green
            '#1982C4', // Blue
            '#6A4C93', // Indigo
            '#FF9F2E', // Orange (Slightly different shade)
            '#FFD56A', // Light Orange
            '#FF61A6', // Pink
            '#D3F8E2', // Light Green
            '#B2EF9B', // Lighter Green
            '#EE6055', // Soft Red
          ],
        },
      ],
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {position: 'bottom' as const},
      },
    }

    return (
      <div className='col-lg-12 grid-margin stretch-card'>
        <div className='card'>
          <div className='card-header'>
            <h2>Reports Uploaded Within The Period By Type</h2>
          </div>
          <div className='card-body d-flex'>
            <div className='table-responsive flex-grow-1'>
              <table className='table table-striped'>
                <thead>
                  <tr style={{fontWeight: 'bold', fontSize: '1.1em'}}>
                    <th>Path Report Type</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {statistics.HealthDataStats.pathReportsByType.map(({PathReportType, total}) => (
                    <tr key={PathReportType}>
                      <td>{PathReportType}</td>
                      <td>{total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='chart-container flex-grow-1'>
              <Pie data={pieData} options={options} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const entriesPerPage = 10

  const [healthEpisodesPage, setHealthEpisodesPage] = useState(0)

  const healthEpisodesByPage = useMemo(() => {
    const chunks = []
    const data = statistics?.HealthDataStats?.healthEpisodesByDiagnosis || []
    for (let i = 0; i < data.length; i += entriesPerPage) {
      chunks.push(data.slice(i, i + entriesPerPage))
    }
    return chunks
  }, [statistics, healthEpisodesPage])

  const renderHealthEpisodes = () => {
    if (!healthEpisodesByPage.length) return null

    const currentPageData = healthEpisodesByPage[healthEpisodesPage]
    const pieData = {
      labels: currentPageData.map((d) => d.DiagnosisName),
      datasets: [
        {
          data: currentPageData.map((d) => d.total),
          backgroundColor: [
            '#FF595E', // Red
            '#FFCA3A', // Orange
            '#8AC926', // Lime Green
            '#1982C4', // Blue
            '#6A4C93', // Indigo
            '#FF9F2E', // Orange (Slightly different shade)
            '#FFD56A', // Light Orange
            '#FF61A6', // Pink
            '#D3F8E2', // Light Green
            '#B2EF9B', // Lighter Green
            '#EE6055', // Soft Red
          ],
          hoverBackgroundColor: [
            '#FF595E', // Red
            '#FFCA3A', // Orange
            '#8AC926', // Lime Green
            '#1982C4', // Blue
            '#6A4C93', // Indigo
            '#FF9F2E', // Orange (Slightly different shade)
            '#FFD56A', // Light Orange
            '#FF61A6', // Pink
            '#D3F8E2', // Light Green
            '#B2EF9B', // Lighter Green
            '#EE6055', // Soft Red
          ],
        },
      ],
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {position: 'bottom' as const},
      },
    }

    return (
      <div className='col-lg-12 grid-margin stretch-card'>
        <div className='card'>
          <div className='card-header'>
            <h2>Diagnosis Related To Health Episodes Created Within The Period</h2>
          </div>
          <div className='card-body d-flex'>
            <div className='chart-container flex-grow-1'>
              <Pie data={pieData} options={options} />
            </div>
            <div className='table-responsive flex-grow-1'>
              <table className='table table-striped'>
                <thead>
                  <tr style={{fontWeight: 'bold', fontSize: '1.1em'}}>
                    <th>Diagnosis Name</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map(({DiagnosisName, total}) => (
                    <tr key={DiagnosisName}>
                      <td>{DiagnosisName}</td>
                      <td>{total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='card-footer'>
            <div className='pagination-controls'>
              <button
                className='page-button'
                onClick={() => setHealthEpisodesPage((prev) => Math.max(prev - 1, 0))}
                disabled={healthEpisodesPage === 0}
              >
                Previous
              </button>
              <button
                className='page-button'
                onClick={() =>
                  setHealthEpisodesPage((prev) =>
                    Math.min(prev + 1, healthEpisodesByPage.length - 1)
                  )
                }
                disabled={healthEpisodesPage === healthEpisodesByPage.length - 1}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='dashboard-stats-container'>
      <div className='row mb-4'>
        <div className='col-12 mb-8'>
          <select onChange={handleTimeFrameChange} value={timeFrame} className='form-select'>
            <option value='1'>Today</option>
            <option value='3'>Last 3 Days</option>
            <option value='7'>Last Week</option>
            <option value='15'>Last 15 Days</option>
            <option value='30'>Last Month</option>
            <option value='90'>Last 3 Months</option>
            <option value='inf'>Lifetime</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className='loader-container'>
          <div className='spinner'></div>
        </div>
      ) : error ? (
        <div className='error-message'>{error}</div>
      ) : (
        <>
          <div className='row mb-4'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='card'>
                <div className='card-header'>
                  <h2>Overview of Accounts</h2>
                </div>
                <div className='card-body'>{renderKeyValuePairs()}</div>
              </div>
            </div>
            {renderGenderDistribution()}
          </div>
          <div className='row'>
            {renderAgeBrackets()}
            {renderCityDistribution()}
          </div>
          <div className='row mb-10'>
            <div className='col-12'>{renderPathReportsByType()}</div>
          </div>

          <div className='row mb-10'>
            <div className='col-12'>{renderHealthEpisodes()}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default DashboardStats
