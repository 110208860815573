import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import * as authHelper from '../../../../auth/core/AuthHelpers'
import {useAuth} from '../../../../auth/core/Auth'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import Select from 'react-select'
import {getBucketURL} from '../../../../../utils/Index'
import config from 'config'

const countries = require('country-data').countries


interface PeopleData {
  Name: string
  CategoryID: number
  Country: string
  DiagnosisID: number
  source: string
  Link: string
  Image: string | undefined
  LicenseType: string
  Author: string
  ImageSourceLink: string
  AdditionalContributors: string
  ProfileImage: File | null
}

const validationSchema = Yup.object().shape({
  Name: Yup.string().trim().required('Name is required'),
  CategoryID: Yup.number().notOneOf([0], 'Category is required'),
  Country: Yup.string().required('Country is required'),
  DiagnosisID: Yup.number().notOneOf([0], 'Diagnosis is required'),
  source: Yup.string().trim().url('Invalid URL format').required('Source is required'),
  Link: Yup.string().trim().url('Invalid URL format').required('Link is required'),
  LicenseType: Yup.string().trim(),
  Image: Yup.string().required('Image is required'),
  Author: Yup.string().trim(),
  ImageSourceLink: Yup.string().trim(),
  AdditionalContributors: Yup.string().trim(),
})

let initialValues: any = {
  Name: '',
  CategoryID: 0,
  Country: '',
  DiagnosisID: 0,
  source: '',
  Link: '',
  Image: '',
  LicenseType: '',
  Author: '',
  ImageSourceLink: '',
  AdditionalContributors: '',
  ProfileImage: null,
}

export function AddEditFamousPeople() {
  const [loading, setLoading] = useState(false)
  const [categoryOptions, setcategoryOptions] = useState([{ID: '', CategoryName: ''}])
  const [diagnosisOptions, setdiagnosisOptions] = useState([{ID: '', DiagnosisName: ''}])
  const [CountryOptions, setCountryOptions] = useState([{countryCallingCodes: '', countryName: ''}])
  const TOKEN = authHelper.getAuth()
  const {saveAuth, setCurrentUser} = useAuth()
  const {peopleId} = useParams<{peopleId: string}>()
  const navigate = useNavigate()

  const formik = useFormik<PeopleData>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      // Handle form submission
      // Create form data with only the changed values
      let formData = new FormData()
      let error = false
      const appendFormData = (fieldName: string, fieldValue: any) => {
        const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue
        if (trimmedValue === '') {
          // Raise an error or handle empty value as needed
          formik.setFieldError(fieldName, `${fieldName} is required`)
          error = true
        }
        if (trimmedValue !== initialValues[fieldName]) {
          formData.append(fieldName, trimmedValue)
        }
      }

      appendFormData('Name', values.Name)
      appendFormData('CategoryID', String(values.CategoryID))
      appendFormData('Country', values.Country)
      appendFormData('DiagnosisID', String(values.DiagnosisID))
      appendFormData('source', values.source)
      appendFormData('Link', values.Link)
      appendFormData('LicenseType', values.LicenseType)
      appendFormData('Author', values.Author)
      appendFormData('ImageSourceLink', values.ImageSourceLink)
      appendFormData('AdditionalContributors', values.AdditionalContributors)

      if (values.ProfileImage) {
        formData.append('FamousPeopleImage', values.ProfileImage)
      }
      if (error) {
        return
      }
      // You can now use the 'formData' object as needed
      // Display the formData object in the console
      if (peopleId) {
        axios
          .put(
            `${config.SERVER_URL}/famouspeoples/updatefamouspeople/${peopleId}`,
            formData,
            {
              headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
            }
          )
          .then((res: any) => {
            console.log(res)
            if (res.data.message) {
              navigate('/apps/famous-peoples-management/famous-peoples')
            }
          })
          .catch((err: any) => {
            console.log(err)
            saveAuth(undefined)
            setCurrentUser(undefined)
          })
      } else {
        axios
          .post(`${config.SERVER_URL}/famouspeoples/addfamouspeople`, formData, {
            headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
          })
          .then((res: any) => {
            console.log(res)
            if (res.data.message) {
              navigate('/apps/famous-peoples-management/famous-peoples')
            }
          })
          .catch((err: any) => {
            console.log(err)
            saveAuth(undefined)
            setCurrentUser(undefined)
          })
      }

      setLoading(false)
    },
  })

  const getDiagnosys = () => {
    axios
      .get(`${config.SERVER_URL}/diagnosis/all`, {
        headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
      })
      .then((res) => {
        console.log(res)
        if (res.data.diagnosisList.length > 0) {
          setdiagnosisOptions(res.data.diagnosisList)
        }
      })
      .catch((err) => {
        saveAuth(undefined)
        setCurrentUser(undefined)
      })
  }
  const getCategories = () => {
    axios
      .get(`${config.SERVER_URL}/famouspeoples/getallfamouspeoplecategory`, {
        headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
      })
      .then((res) => {
        console.log(res)
        if (res.data.famousPeopleCategory.length > 0) {
          setcategoryOptions(res.data.famousPeopleCategory)
        }
      })
      .catch((err) => {
        saveAuth(undefined)
        setCurrentUser(undefined)
      })
  }

  const getFamousPeople = () => {
   
    axios
      .get(`${config.SERVER_URL}/famouspeoples/getfamouspeoplebyid/${peopleId}`, {
        headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
      })
      .then((res) => {
        if (res.data.famousPeople) {
          const data = res.data.famousPeople
          // Assign API data to form fields
          formik.setValues({
            Name: data.Name ? data.Name : initialValues.Name,
            CategoryID: data.CategoryID ? data.CategoryID : initialValues.CategoryID,
            Country: data.Country ? data.Country : initialValues.Country,
            DiagnosisID: data.DiagnosisID ? data.DiagnosisID : initialValues.DiagnosisID,
            source: data.source ? data.source : initialValues.source,
            Link: data.Link ? data.Link : initialValues.Link,
            Image: data.Image ? data.Image : initialValues.Image,
            Author: data.Author ? data.Author : initialValues.Author,
            ImageSourceLink: data.ImageSourceLink
              ? data.ImageSourceLink
              : initialValues.ImageSourceLink,
            AdditionalContributors: data.AdditionalContributors
              ? data.AdditionalContributors
              : initialValues.AdditionalContributors,
            LicenseType: data.LicenseType ? data.LicenseType : initialValues.LicenseType,
            ProfileImage: null,
          })
          initialValues.Name = data.Name ? data.Name : ''
          initialValues.CategoryID = data.CategoryID ? data.CategoryID : ''
          initialValues.Country = data.Country ? data.Country : ''
          initialValues.DiagnosisID = data.DiagnosisID ? data.DiagnosisID : ''
          initialValues.source = data.source ? data.source : ''
          initialValues.Link = data.Link ? data.Link : ''
          initialValues.Image = data.Image ? data.Image : ''
          initialValues.Author = data.Author ? data.Author : ''
          initialValues.ImageSourceLink = data.ImageSourceLink ? data.ImageSourceLink : ''
          initialValues.AdditionalContributors = data.AdditionalContributors
            ? data.AdditionalContributors
            : ''
          initialValues.LicenseType = data.LicenseType ? data.LicenseType : ''

          getURl(formik?.values?.Image, formik?.setFieldValue)
        } else {
          saveAuth(undefined)
          setCurrentUser(undefined)
        }
      })
      .catch((err) => {
        saveAuth(undefined)
        setCurrentUser(undefined)
      })
  }
  useEffect(() => {
    if (peopleId) {
      getFamousPeople()
    } else {
      formik.setValues({
        Name: '',
        CategoryID: 0,
        Country: '',
        DiagnosisID: 0,
        source: '',
        Link: '',
        Image: '',
        ImageSourceLink: '',
        AdditionalContributors: '',
        Author: '',
        LicenseType: '',
        ProfileImage: null,
      })
    }
    getDiagnosys()
    getCategories()

    var result: any = []
    var processedCountries: any = {}
    for (var countryCode in countries) {
      if (countries.hasOwnProperty(countryCode) && countryCode !== 'all') {
        var country = countries[countryCode]
        var countryCallingCodes = country.countryCallingCodes
        var countryName = country.name
        if (!processedCountries[countryCallingCodes] && !processedCountries[countryName]) {
          var countryObject = {
            countryCallingCodes: countryCallingCodes,
            countryName: countryName,
          }
          result.push(countryObject)
          processedCountries[countryCallingCodes] = true
          processedCountries[countryName] = true
        }
      }
    }
    setCountryOptions(result)
  }, [])
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? 'transparent' : provided.borderColor,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      border: 'none',
      outline: state.isFocused ? 'none' : 'none',
      backgroundColor: '#F5F8FA',
      marginTop: '5px',
      width: '520px',
    }),
  }
  const handleProfileChange = async (e: any, setFieldValue: any) => {
    if (e.target.value) {
      const file = e.target.files[0]
      // Handle file change logic

      setFieldValue('Image', URL.createObjectURL(file))
      setFieldValue('ProfileImage', file)
    }
  }

  // useEffect(() => {
  //   getURl(formik?.values?.Image, formik?.setFieldValue)
  // }, [loading,setLoading])

  const getURl = async (url: any, setFieldValue: any) => {
    let imageUrl = await getBucketURL(url)
    setFieldValue('Image', imageUrl)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='image-input-outline '>
        <div
        // className='image-input image-input-outline text-center'
        // data-kt-image-input='true'
        // style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
        >
          <div
          // className='image-input-wrapper w-125px h-125px '
          // style={{ backgroundImage: `url(${toAbsoluteUrl(formik.values.Image ? formik.values.Image : '')})` }}
          >
            <img
              src={formik.values.Image ? formik.values.Image : '/media/avatars/blank.png'}
              alt='famous people category image'
              style={{width: '125px', height: '125px', objectFit: 'contain'}}
              loading='lazy'
            />
          </div>
          {formik.touched.Image && formik.errors.Image && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{String(formik.errors.Image)}</span>
              </div>
            </div>
          )}
          <label
            htmlFor='contained-button-file'
            className='btn btn-primary fw-bolder fs-6 px-8 py-4 my-3'
          >
            Upload
            <input
              type='file'
              accept='image/*'
              style={{display: 'none'}}
              id='contained-button-file'
              name='ProfileImageUrl'
              onChange={(e) => handleProfileChange(e, formik.setFieldValue)}
            />
          </label>
        </div>
      </div>

      <br />
      <div className='row'>
        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='Name' className='form-label fs-6 fw-bolder text-dark'>
            Name
          </label>
          <input
            id='Name'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.Name && formik.errors.Name}
              // { 'is-valid': formik.touched.Name && !formik.errors.Name }
            )}
            {...formik.getFieldProps('Name')}
            onChange={formik.handleChange}
          />
          {formik.touched.Name && formik.errors.Name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.Name}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='CategoryID' className='form-label fs-6 fw-bolder text-dark'>
            Famous People Category
          </label>

          <Select
            options={categoryOptions}
            styles={customStyles}
            getOptionLabel={(option) => option.CategoryName}
            getOptionValue={(option) => option.ID}
            className={clsx('form-control bg-transparent p-0', {
              'is-invalid': formik.touched.CategoryID && formik.errors.CategoryID,
            })}
            value={categoryOptions.find((option: any) => option.ID === formik.values.CategoryID)}
            onChange={(selectedOption) =>
              formik.setFieldValue('CategoryID', selectedOption?.ID || '')
            }
            placeholder='Select Category'
            onBlur={formik.handleBlur('CategoryID')}
          />
          {formik.touched.CategoryID && formik.errors.CategoryID && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.CategoryID}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='form-group fv-row col-lg-6 mb-8'>
          <label htmlFor='Country' className='form-label fs-6 fw-bolder text-dark'>
            Country
          </label>
          <Select
            options={CountryOptions}
            styles={customStyles}
            getOptionLabel={(option) => option.countryName}
            getOptionValue={(option) => option.countryName}
            className={clsx('form-control bg-transparent p-0', {
              'is-invalid': formik.touched.Country && formik.errors.Country,
            })}
            value={CountryOptions.find(
              (option: any) => option.countryName === formik.values.Country
            )}
            onChange={(selectedOption) =>
              formik.setFieldValue('Country', selectedOption?.countryName || '')
            }
            placeholder='Select Country'
            onBlur={formik.handleBlur('Country')}
          />
          {formik.touched.Country && formik.errors.Country && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.Country}</span>
              </div>
            </div>
          )}
        </div>
        <div className='form-group fv-row col-lg-6 mb-8'>
          <label htmlFor='DiagnosisID' className='form-label fs-6 fw-bolder text-dark'>
            Diagnosis Name
          </label>
          <Select
            options={diagnosisOptions}
            styles={customStyles}
            getOptionLabel={(option) => option.DiagnosisName}
            getOptionValue={(option) => option.ID}
            classNamePrefix='select diagnosis'
            className={clsx('form-control bg-transparent p-0', {
              'is-invalid': formik.touched.DiagnosisID && formik.errors.DiagnosisID,
            })}
            value={diagnosisOptions.find((option: any) => option.ID === formik.values.DiagnosisID)}
            onChange={(selectedOption) =>
              formik.setFieldValue('DiagnosisID', selectedOption?.ID || '')
            }
            placeholder='Select Diagnosis'
            onBlur={formik.handleBlur('DiagnosisID')}
          />

          {formik.touched.DiagnosisID && formik.errors.DiagnosisID && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.DiagnosisID}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='source' className='form-label fs-6 fw-bolder text-dark'>
            Source
          </label>
          <input
            id='source'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.source && formik.errors.source}
              // { 'is-valid': formik.touched.source && !formik.errors.source }
            )}
            {...formik.getFieldProps('source')}
            onChange={formik.handleChange}
          />
          {formik.touched.source && formik.errors.source && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.source}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='Link' className='form-label fs-6 fw-bolder text-dark'>
            Link
          </label>
          <input
            id='Link'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.Link && formik.errors.Link}
              // { 'is-valid': formik.touched.Link && !formik.errors.Link }
            )}
            {...formik.getFieldProps('Link')}
            onChange={formik.handleChange}
          />
          {formik.touched.Link && formik.errors.Link && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.Link}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='LicenseType' className='form-label fs-6 fw-bolder text-dark'>
            License Type
          </label>
          <input
            id='LicenseType'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.LicenseType && formik.errors.LicenseType}
              // { 'is-valid': formik.touched.LicenseType && !formik.errors.LicenseType }
            )}
            {...formik.getFieldProps('LicenseType')}
            onChange={formik.handleChange}
          />
          {formik.touched.LicenseType && formik.errors.LicenseType && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.LicenseType}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='Author' className='form-label fs-6 fw-bolder text-dark'>
            Author
          </label>
          <input
            id='Author'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.Author && formik.errors.Author}
              // { 'is-valid': formik.touched.Author && !formik.errors.Author }
            )}
            {...formik.getFieldProps('Author')}
            onChange={formik.handleChange}
          />
          {formik.touched.Author && formik.errors.Author && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.Author}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='ImageSourceLink' className='form-label fs-6 fw-bolder text-dark'>
            Image Source Link
          </label>
          <input
            id='ImageSourceLink'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.ImageSourceLink && formik.errors.ImageSourceLink}
              // { 'is-valid': formik.touched.ImageSourceLink && !formik.errors.ImageSourceLink }
            )}
            {...formik.getFieldProps('ImageSourceLink')}
            onChange={formik.handleChange}
          />
          {formik.touched.ImageSourceLink && formik.errors.ImageSourceLink && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.ImageSourceLink}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row col-lg-6 mb-8'>
          <label htmlFor='AdditionalContributors' className='form-label fs-6 fw-bolder text-dark'>
            Additional Contributors
          </label>
          <input
            id='AdditionalContributors'
            type='text'
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid':
                  formik.touched.AdditionalContributors && formik.errors.AdditionalContributors,
              }
              // { 'is-valid': formik.touched.AdditionalContributors && !formik.errors.AdditionalContributors }
            )}
            {...formik.getFieldProps('AdditionalContributors')}
            onChange={formik.handleChange}
          />
          {formik.touched.AdditionalContributors && formik.errors.AdditionalContributors && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.AdditionalContributors}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-8'>
        <button
          type='submit'
          className='btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3'
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        <div
          onClick={() => navigate(-1)}
          className='btn btn-secondary fw-bolder fs-6 px-8 py-4 my-3 me-3'
        >
          Cancel
        </div>
      </div>
    </form>
  )
}
