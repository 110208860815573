import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../auth'
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from 'config';

const validationSchema = Yup.object().shape({
    EthnicityName: Yup.string().transform((value) => value.trim()).required('Ethnicity Name is required'),
});

export default function EthnicityList() {
    const { saveAuth, setCurrentUser } = useAuth()
    const TOKEN = authHelper.getAuth()
    const [loading, setLoading] = useState(true)
    const [EthnicityList, setEthnicityList] = useState([])
    const [EthnicityName, setEthnicityName] = useState('');
    const [EthnicityID, setEthnicityID] = useState(null);
    const [editEthnicity, seteditEthnicity] = useState(false);
    const [submitEthnicity, setsubmitEthnicity] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let [pagenumber, setPagenumber] = useState(1)
    const pagesize = 10
    const [length, setLength] = useState(0)
    let pageCount = Math.ceil(length / pagesize)

    const handleShow = (item: any) => {
        setsubmitEthnicity(false)
        setShowModal(true);
        seteditEthnicity(true);
        formik.setValues({EthnicityName:item.Name})
        setEthnicityName(item.Name);
        setEthnicityID(item.ID);
    };
    const openModal = () => {
        setsubmitEthnicity(false)
        setShowModal(true);
    };
    const changePage = ({ selected }: any) => {
        setPagenumber(selected + 1)
        pagenumber = selected + 1
        getEthnicity(pagenumber)
    }


    const deleteEthnicity = (id: any) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'you want to delete this entry ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                      axios.delete(`${config.SERVER_URL}/ethnicity/deleteethnicity/${id}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                            if (res.data.message) {
                                getEthnicity(pagenumber)
                            }
                        })
                    }
                },
                {
                    label: 'Cancel',
                }
            ]
        });


    }

    const handleClose = () => {
        setShowModal(false);
        setsubmitEthnicity(false)
        seteditEthnicity(false);
        setEthnicityName('');
        setEthnicityID(null)
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            EthnicityName: '',
        },
        validationSchema,
        onSubmit: (values) => {
            const trimmedEthnicityName = values.EthnicityName.trim();

      // Check if the DiagnosisName is empty after removing spaces
      if (trimmedEthnicityName === '') {
        formik.setFieldError('EthnicityName', 'Ethnicity Name is required');
        formik.setFieldValue('EthnicityName','')
        return;
      }
            setsubmitEthnicity(true)

            if (!editEthnicity) {

               axios.post(`${config.SERVER_URL}/ethnicity/addethnicity`, { Name: trimmedEthnicityName }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res)
                    if (res.data.message) {
                        handleClose()
                        getEthnicity(pagenumber)
                    }
                }).catch((err:any)=>{
                    console.log(err)
                    setsubmitEthnicity(false);
                    formik.setFieldError('EthnicityName',err.response.data.message)
                  });
            } else {
               axios.put(`${config.SERVER_URL}/ethnicity/updateethnicity/${EthnicityID}`, { Name: trimmedEthnicityName }, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res) => {
                    console.log(res)
                    if (res.data.message) {
                        handleClose()
                        getEthnicity(pagenumber)
                    }
                }).catch((err:any)=>{
                    console.log(err)
                    setsubmitEthnicity(false);
                    formik.setFieldError('EthnicityName',err.response.data.message)
                  });
            }
        },
    });

    const getEthnicity = (pagenumber: any) => {
        setLoading(true)
        try {
            axios.get(config.SERVER_URL + `/ethnicity/getallethnicitywithpagination?page=${pagenumber}&limit=${pagesize}`, { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }).then((res: any) => {
                if (res.data) {
                    setLoading(false)
                    if (res.data.ethnicity.length !== 0) {
                        setEthnicityList(res.data.ethnicity)
                        setLength(res.data.totalRecords)
                    } else {
                        if (pagenumber > 1) {
                            getEthnicity(pagenumber - 1)
                            setPagenumber(pagenumber - 1)
                        }
                    }
                } else {
                    saveAuth(undefined)
                    setCurrentUser(undefined)
                }
            }).catch((err) => {
                console.log(err)
                setCurrentUser(undefined)
                saveAuth(undefined)
            })
        } catch (err) {
            console.log(err)
            setCurrentUser(undefined)
            saveAuth(undefined)
        }
    }

    useEffect(() => {
        getEthnicity(1)
    }, [])

    return (<>
        <div className="m-0 p-0 h-100">
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 className="card-title">General Questions</h4> */}
                        <p className="card-description d-flex justify-content-end">
                            <button type='button' className='btn btn-primary' onClick={openModal}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Ethnicity
                            </button>
                        </p>
                        {EthnicityList.length > 0 ? <div> <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive" >
                            <table className="table table-striped h-100">
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Ethnicity Name</th>
                                        <th className='fw-bolder'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        EthnicityList.map((item: any, index) => (
                                            <tr key={index}>


                                                <td>
                                                    {item.Name ? item.Name : '-'}
                                                </td>

                                                <td>
                                                    <div className='d-flex '>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}  ><i className="fa fa-edit fa-5x fs-2" /></div>

                                                        <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteEthnicity(item.ID)}> <i className="fa fa-trash fa-5x fs-2" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className='m-auto mt-6'>
                                {EthnicityList.length > 0 ? <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationbtns"}
                                    previousLinkClassName={"prevBtn"}
                                    nextLinkClassName={"nextBtn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                /> : ""}
                            </div>
                        </div> :  loading ? (
              <span className='indicator-progress text-center' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ):<div className='text-center w-90'><h2 className='mt-9'>Records not found</h2></div>
                        } </div></div></div></div>
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{editEthnicity?"Edit Ethnicity":"Add Ethnicity"} </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className='row '>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="EthnicityName">Ethnicity Name</label>
                        <input
                            type="text"
                            className={`form-control ${formik.touched.EthnicityName && formik.errors.EthnicityName ? 'is-invalid' : ''}`}
                            id="EthnicityName"
                            name="EthnicityName"
                            value={formik.values.EthnicityName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                        />
                        {formik.touched.EthnicityName && formik.errors.EthnicityName && (
                            <div className="invalid-feedback">{formik.errors.EthnicityName}</div>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitEthnicity} type='submit'>
                        {!submitEthnicity ? 'Save' : 'Submiting'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
    )
}
