import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import {Modal, Button, Toast} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import * as authHelper from '../../../auth/core/AuthHelpers'
import {useAuth} from '../../../auth/core/Auth'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import {getBucketURL} from '../../../../utils/Index'
import config from 'config'


const validationSchema = Yup.object().shape({
  ThumbnailURL: Yup.string()
    .transform((value) => value.trim())
    .required('Image is required'),
  ServiceName: Yup.string()
    .transform((value) => value.trim())
    .required('ServiceName is required'),
  Price: Yup.number().required('Price is required'),
  Description: Yup.string().transform((value) => value.trim()),
  Offer: Yup.string()
    .transform((value) => value.trim())
    .required('Offer is required'),
  OfferValue: Yup.string()
    .transform((value) => value.trim())
    .required('OfferValue is required'),
  AHPoints: Yup.string()
    .transform((value) => value.trim())
    .required('AHPoints is required'),
})

let initialValues: any = {
  ThumbnailURL: '',
  ServiceName: '',
  Price: 0,
  Description: '',
  Offer: '',
  IsRedemable: '',
  OfferPrice: 0,
  OfferValue: 0,
  AHPoints: 0,
  IsOnline: false,
}

export function AddEditAhPartnerService() {
  let [loading, setLoading] = useState(false)
  const TOKEN = authHelper.getAuth()
  const {saveAuth, setCurrentUser} = useAuth()
  const {serviceId} = useParams<{serviceId: string}>()
  const navigate = useNavigate()
  const [showPartnerModal, setShowPartnerModal] = useState(false)
  const [ahPartnerOfServiceList, setAhPartnerOfServiceList] = useState([])
  const [ahPartnerList, setAhPartnerList] = useState([{ID: '', PartnerName: ''}])
  const [submitAhPartnerService, setSubmitAhPartnerService] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showPartnerCategoryModal, setShowPartnerCategoryModal] = useState(false)
  const [ahPartnerCategoryOfServiceList, setAhPartnerCategoryOfServiceList] = useState([])
  const [ahPartnerCategoryList, setAhPartnerCategoryList] = useState([{ID: '', CategoryName: ''}])
  const [submitAhPartnerCategoryService, setSubmitAhPartnerCategoryService] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      loading = true
      setLoading(true)
      // Handle form submission
      console.log(values)
      // Create form data with only the changed values
      let formData = new FormData()
      let error = false
      const appendFormData = (fieldName: string, fieldValue: any) => {
        const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue

        // if (trimmedValue === "") {
        //     // Raise an error or handle empty value as needed
        //     formik.setFieldError(fieldName, `${fieldName} is required`)
        //     error = true
        // }
        if (trimmedValue !== initialValues[fieldName]) {
          formData.append(fieldName, trimmedValue)
        }
      }
      if (values.ProfileImage) {
        appendFormData('AHPartnerServiceMasterImage', values.ProfileImage)
      }
      appendFormData('ServiceName', values.ServiceName)
      appendFormData('Price', String(values.Price))
      appendFormData('OfferPrice', String(values.OfferPrice))
      appendFormData('OfferValue', String(values.OfferValue))
      appendFormData('Description', values.Description)
      appendFormData('Offer', values.Offer)
      appendFormData('AHPoints', values.AHPoints)
      // appendFormData('IsRedemable', values.IsRedemable == true ? '1' : '0');
      appendFormData('IsOnline', values.IsOnline === true ? '1' : '0')
      if (error) {
        setLoading(false)
        return
      }
      console.log(appendFormData)
      if (serviceId) {
        axios
          .put(
            `${config.SERVER_URL}/ahpartner/updateahpartnerservicemaster/${serviceId}`,
            formData,
            {
              headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
            }
          )
          .then((res: any) => {
            if (res.data.message) {
              console.log(res.data.message)
              setShowToast(true)
              setTimeout(() => setShowToast(false), 3000)
              //formik.resetForm()
              //navigate('/apps/ah-partner-service-management/services')
            }
          })
          .catch((err: any) => {
            console.log(err)
            // saveAuth(undefined);
            // setCurrentUser(undefined);
          })
      } else {
        axios
          .post(
            `${config.SERVER_URL}/ahpartner/addahpartnerservicemaster`,
            formData,
            {
              headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
            }
          )
          .then((res: any) => {
            console.log(res)
            if (res.data.message) {
              formik.resetForm()
              navigate('/apps/ah-partner-service-management/services')
            }
          })
          .catch((err: any) => {
            console.log(err)
            if (err.response && err.response.status) {
              const statusCode = err.response.status
              console.log(statusCode)
              if (statusCode !== 500) {
                const errorMessage =
                  err.response?.data?.message || 'An error occurred while processing your request.'
                alert(errorMessage)
              }
            }
            // saveAuth(undefined);
            // setCurrentUser(undefined);
          })
      }

      setLoading(false)
    },
  })

  function calculateOfferPrice() {
    let offerPrice = formik.values.OfferPrice
    if (formik.values.Offer === 'Free') {
      offerPrice = 0
    } else if (formik.values.Offer === 'Percentage') {
      const percentage = parseFloat(formik.values.OfferValue)
      offerPrice = formik.values.Price - (formik.values.Price * percentage) / 100
    } else if (formik.values.Offer === 'Amount') {
      const amount = parseFloat(formik.values.OfferValue)
      offerPrice = formik.values.Price - amount
    }
    if (formik.values.Offer && formik.values.OfferValue) {
      if (offerPrice < 0) {
        formik.setFieldValue('OfferPrice', 0)
      } else {
        formik.setFieldValue('OfferPrice', offerPrice)
      }
    } else {
      formik.setFieldValue('OfferPrice', 0)
    }
  }

  const getAhPartnerService = () => {
    axios
      .get(
        `${config.SERVER_URL}/ahpartner/getahpartnerservicemastersbyid/${serviceId}`,
        {
          headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken},
        }
      )
      .then((res) => {
        if (res.data) {
          const data = res.data

          // Assign API data to form fields
          formik.setValues({
            ServiceName: data.ServiceName ? data.ServiceName : initialValues.ServiceName,
            IsOnline: data.IsOnline === 1 ? true : false,
            Price: data.Price ? data.Price : initialValues.Price,
            Description: data.Description ? data.Description : initialValues.Description,
            Offer: data.Offer ? data.Offer : initialValues.Offer,
            ThumbnailURL: data.ThumbnailURL ? data.ThumbnailURL : initialValues.ThumbnailURL,
            OfferValue: data.OfferValue ? data.OfferValue : initialValues.OfferValue,
            OfferPrice: data.OfferPrice ? data.OfferPrice : initialValues.OfferPrice,
            AHPoints: data.AHPoints ? data.AHPoints : initialValues.AHPoints,
          })
          // initialValues.ServiceName = data.ServiceName
          // initialValues.IsOnline = data.IsOnline === 1 ? true : false
          // initialValues.Price = data.Price
          // initialValues.ThumbnailURL = data.ThumbnailURL
          // initialValues.Offer = data.Offer
          // initialValues.Description = data.Description
          // initialValues.OfferPrice = data.OfferPrice
          // initialValues.OfferValue = data.OfferValue
          // initialValues.AHPoints = data.AHPoints
        } else {
          saveAuth(undefined)
          setCurrentUser(undefined)
        }
      })
      .catch((err) => {
        saveAuth(undefined)
        setCurrentUser(undefined)
      })
  }

  useEffect(() => {
    if (serviceId) {
      getAhPartnerService()
      getAhPartnerOfServices()
      getAhPartnerCategoryOfServices()
    } else {
      formik.setValues({
        ThumbnailURL: '',
        ServiceName: '',
        Price: '',
        Description: '',
        Offer: '',
        IsRedemable: '',
        OfferPrice: 0,
        OfferValue: '',
        AHPoints: '',
        IsOnline: '',
      })
    }
  }, [])

  useEffect(() => {
    getAhPartnerOfServices()
    getAhPartnerCategoryOfServices()
  }, [])

  const handleProfileChange = async (e: any, setFieldValue: any) => {
    if (e.target.value) {
      const file = e.target.files[0]
      // Handle file change logic

      setFieldValue('ThumbnailURL', URL.createObjectURL(file))
      setFieldValue('ProfileImage', file)
      formik.setFieldError('ThumbnailURL', '')
    }
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? 'transparent' : provided.borderColor,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      border: 'none',
      outline: state.isFocused ? 'none' : 'none',
      backgroundColor: '#F5F8FA',
      marginTop: '5px',
      width: '100%',
    }),
  }

  const options = [
    {value: 'Free', label: 'Free'},
    {value: 'Percentage', label: 'Percentage'},
    {value: 'Amount', label: 'Amount'},
  ]

  const openPartnerModal = () => {
    setShowPartnerModal(true)
  }

  const handlePartnerClose = () => {
    setShowPartnerModal(false)
    setSubmitAhPartnerService(false)
    partnerFormik.resetForm()
  }

  const getAhPartnerOfServices = () => {
    setLoading(true)
    try {
      axios
        .get(
          `${config.SERVER_URL}/ahpartner/getahpartnersofservice/${serviceId}?page=1`,
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res) => {
          if (res.data) {
            setLoading(false)
            if (res.data.Partners.length !== 0) {
              setAhPartnerList(res.data.Partners)
            }
            if (res.data.PartnerService.length !== 0) {
              setAhPartnerOfServiceList(res.data.PartnerService)
            }
          } else {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
        .catch((err) => {
          console.log(err)
          setCurrentUser(undefined)
          saveAuth(undefined)
        })
    } catch (err) {
      console.log(err)
      setCurrentUser(undefined)
      saveAuth(undefined)
    }
  }
  const deleteAhPartnerService = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to delete this entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(
                `${config.SERVER_URL}/ahpartner/deleteahpartnerservice/${id}`,
                {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
              )
              .then((res) => {
                if (res.data.message) {
                  getAhPartnerOfServices()
                }
              })
          },
        },
        {
          label: 'Cancel',
        },
      ],
    })
  }

  const partnerFormik = useFormik({
    initialValues: {
      AHPartnerID: '',
    },
    validationSchema: Yup.object().shape({
      AHPartnerID: Yup.string()
        .transform((value) => value.trim())
        .required('Please select Partner'),
    }),
    onSubmit: (values) => {
      // Handle form submission
      // Check if the valu is empty
      console.log(values.AHPartnerID)
      if (values.AHPartnerID === '') {
        partnerFormik.setFieldError('AHPartnerID', 'Please select Partner.')
        partnerFormik.setFieldValue('AHPartnerID', '')
        return
      }
      setSubmitAhPartnerService(true)

      axios
        .post(
          `${config.SERVER_URL}/ahpartner/addahpartnerservice`,
          {AHPartnerServiceMasterID: serviceId, AHPartnerID: values.AHPartnerID},
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            handlePartnerClose()
            partnerFormik.resetForm()
            setSubmitAhPartnerService(false)
            getAhPartnerOfServices()
          }
        })
        .catch((err: any) => {
          console.log(err)
          setSubmitAhPartnerService(false)
          partnerFormik.setFieldError('AHPartnerID', err.response.data.message)
        })
    },
  })

  const openPartnerCategoryModal = () => {
    setShowPartnerCategoryModal(true)
  }

  const handlePartnerCategoryClose = () => {
    setShowPartnerCategoryModal(false)
    setSubmitAhPartnerCategoryService(false)
    partnerCategoryFormik.resetForm()
  }

  const getAhPartnerCategoryOfServices = () => {
    setLoading(true)
    try {
      axios
        .get(
          `${config.SERVER_URL}/ahpartner/getahpartnercategoryofservice/${serviceId}?page=1`,
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res) => {
          if (res.data) {
            setLoading(false)
            if (res.data.PartnerCategory.length !== 0) {
              setAhPartnerCategoryList(res.data.PartnerCategory)
            }
            if (res.data.PartnerCategoryService.length !== 0) {
              setAhPartnerCategoryOfServiceList(res.data.PartnerCategoryService)
            }
          } else {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
        .catch((err) => {
          console.log(err)
          setCurrentUser(undefined)
          saveAuth(undefined)
        })
    } catch (err) {
      console.log(err)
      setCurrentUser(undefined)
      saveAuth(undefined)
    }
  }
  const deleteAhPartnerCategoryService = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to delete this entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(
                `${config.SERVER_URL}/ahpartner/deleteahpartnercategoryservice/${id}`,
                {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
              )
              .then((res) => {
                if (res.data.message) {
                  getAhPartnerCategoryOfServices()
                }
              })
          },
        },
        {
          label: 'Cancel',
        },
      ],
    })
  }

  const partnerCategoryFormik = useFormik({
    initialValues: {
      PartnerCategoryID: '',
    },
    validationSchema: Yup.object().shape({
      PartnerCategoryID: Yup.string()
        .transform((value) => value.trim())
        .required('Please select Partner Category.'),
    }),
    onSubmit: (values) => {
      // Handle form submission
      // Check if the valu is empty
      console.log(values.PartnerCategoryID)
      if (values.PartnerCategoryID === '') {
        partnerCategoryFormik.setFieldError('PartnerCategoryID', 'Please select Partner Category.')
        partnerCategoryFormik.setFieldValue('PartnerCategoryID', '')
        return
      }
      setSubmitAhPartnerCategoryService(true)

      axios
        .post(
          `${config.SERVER_URL}/ahpartner/addahpartnercategoryservice`,
          {AHPartnerServiceMasterID: serviceId, PartnerCategoryID: values.PartnerCategoryID},
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            handlePartnerCategoryClose()
            partnerCategoryFormik.resetForm()
            setSubmitAhPartnerCategoryService(false)
            getAhPartnerCategoryOfServices()
          }
        })
        .catch((err: any) => {
          console.log(err)
          setSubmitAhPartnerCategoryService(false)
          partnerCategoryFormik.setFieldError('PartnerCategoryID', err.response.data.message)
        })
    },
  })

  useEffect(() => {
    getURl(formik?.values?.ThumbnailURL, formik?.setFieldValue)
  }, [ahPartnerList])

  const getURl = async (url: any, setFieldValue: any) => {
    let imageUrl = await getBucketURL(url)
    setFieldValue('ThumbnailURL', imageUrl)
  }
  return (
    <div>
      <Toast
        style={{zIndex: 9999}}
        show={showToast}
        onClose={() => setShowToast(false)}
        className='position-fixed top-0 end-0 p-2 m-3 bg-success'
      >
        <Toast.Body>Data updated successfully!</Toast.Body>
      </Toast>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            {serviceId ? (
              <h3 className='fw-bolder m-0'>Edit Details</h3>
            ) : (
              <h3 className='fw-bolder m-0'>Add Details</h3>
            )}
          </div>
        </div>
        <div className='collapse show'>
          <form onSubmit={formik.handleSubmit}>
            <div className='card-body border-top p-9'>
              <div className='image-input-outline '>
                <div
                  // className='image-input image-input-outline text-center'
                  // data-kt-image-input='true'
                  // style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    // className='image-input-wrapper'
                    // style={{
                    //   backgroundImage: `url(${formik.values.ThumbnailURL ? formik.values.ThumbnailURL : ''})`,
                    // }}
                  >
                    <img
                      src={formik.values.ThumbnailURL ? formik.values.ThumbnailURL : '/media/avatars/blank.png'}
                      alt="img"
                      style={{width: '100px', height: '100px', objectFit: 'contain'}}
                      loading='lazy'
                    />
                  </div>
                  {formik.touched.ThumbnailURL && formik.errors.ThumbnailURL && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.ThumbnailURL)}</span>
                      </div>
                    </div>
                  )}
                  <label
                    htmlFor='contained-button-file'
                    className='btn btn-primary fw-bolder fs-6 px-8 py-4 my-3'
                  >
                    Upload
                    <input
                      id='contained-button-file'
                      type='file'
                      accept='image/*'
                      style={{display: 'none'}}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.ThumbnailURL && formik.errors.ThumbnailURL}
                        // { 'is-valid': formik.touched.ThumbnailURL && !formik.errors.ThumbnailURL }
                      )}
                      onChange={(e) => handleProfileChange(e, formik.setFieldValue)}
                    />
                  </label>
                </div>
              </div>
              <br />
              <div className='row'>
                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='ServiceName' className='form-label fs-6 fw-bolder text-dark'>
                    Service Name
                  </label>
                  <input
                    id='ServiceName'
                    type='text'
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.ServiceName && formik.errors.ServiceName}
                      // { 'is-valid': formik.touched.ServiceName && !formik.errors.ServiceName }
                    )}
                    {...formik.getFieldProps('ServiceName')}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.ServiceName && formik.errors.ServiceName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.ServiceName)}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='description' className='form-label fs-6 fw-bolder text-dark'>
                    Description
                  </label>
                  <input
                    id='description'
                    type='text'
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.Description && formik.errors.Description}
                      // { 'is-valid': formik.touched.Description && !formik.errors.Description }
                    )}
                    {...formik.getFieldProps('Description')}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.Description && formik.errors.Description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.Description)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='Price' className='form-label fs-6 fw-bolder text-dark'>
                    Price
                  </label>
                  <input
                    id='Price'
                    type='text'
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.Price && formik.errors.Price}
                      // { 'is-valid': formik.touched.Price && !formik.errors.Price }
                    )}
                    {...formik.getFieldProps('Price')}
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '')
                      formik.handleChange({
                        target: {
                          id: 'Price',
                          name: 'Price',
                          value: onlyNumbers,
                        },
                      })
                    }}
                    onBlur={calculateOfferPrice}
                  />
                  {formik.touched.Price && formik.errors.Price && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.Price)}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='Offer' className='form-label fs-6 fw-bolder text-dark'>
                    Offer
                  </label>

                  <Select
                    options={options}
                    styles={customStyles}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    className={clsx('form-control bg-transparent p-0', {
                      'is-invalid': formik.touched.Offer && formik.errors.Offer,
                    })}
                    value={options.find((option: any) => option.value === formik.values.Offer)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('Offer', selectedOption?.value || '')
                    }}
                    placeholder='Select Offer'
                    onBlur={() => {
                      formik.handleBlur('Offer')
                      calculateOfferPrice()
                    }}
                  />
                  {formik.touched.Offer && formik.errors.Offer && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.Offer)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='OfferValue' className='form-label fs-6 fw-bolder text-dark'>
                    Offer Value
                  </label>
                  <input
                    id='OfferValue'
                    type='text'
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.OfferValue && formik.errors.OfferValue}
                      // { 'is-valid': formik.touched.OfferValue && !formik.errors.OfferValue }
                    )}
                    {...formik.getFieldProps('OfferValue')}
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '')
                      formik.handleChange({
                        target: {
                          id: 'OfferValue',
                          name: 'OfferValue',
                          value: onlyNumbers,
                        },
                      })
                    }}
                    onBlur={calculateOfferPrice}
                  />
                  {formik.touched.OfferValue && formik.errors.OfferValue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.OfferValue)}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='OfferPrice' className='form-label fs-6 fw-bolder text-dark'>
                    Offer Price
                  </label>
                  <input
                    id='OfferPrice'
                    type='text'
                    readOnly
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.OfferPrice && formik.errors.OfferPrice}
                      // { 'is-valid': formik.touched.OfferPrice && !formik.errors.OfferPrice }
                    )}
                    {...formik.getFieldProps('OfferPrice')}
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '')
                      formik.handleChange({
                        target: {
                          id: 'OfferPrice',
                          name: 'OfferPrice',
                          value: onlyNumbers,
                        },
                      })
                    }}
                  />
                  {formik.touched.OfferPrice && formik.errors.OfferPrice && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.OfferPrice)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='fv-row col-lg-6 mb-8'>
                  <label htmlFor='AHPoints' className='form-label fs-6 fw-bolder text-dark'>
                    AH-Points
                  </label>
                  <input
                    id='AHPoints'
                    type='text'
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.AHPoints && formik.errors.AHPoints}
                      // { 'is-valid': formik.touched.OfferPrice && !formik.errors.OfferPrice }
                    )}
                    {...formik.getFieldProps('AHPoints')}
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '')
                      formik.handleChange({
                        target: {
                          id: 'AHPoints',
                          name: 'AHPoints',
                          value: onlyNumbers,
                        },
                      })
                    }}
                  />
                  {formik.touched.AHPoints && formik.errors.AHPoints && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.AHPoints)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                {/* <div className="fv-row col-lg-3 mb-8">
                    <label htmlFor="IsRedemable" className="form-label fs-6 fw-bolder text-dark">
                        IsRedemable
                    </label>
                    <input
                        id="IsRedemable"
                        type="checkbox"
                        className={clsx(
                            'form-check-input mx-6',
                            {
                                'is-invalid': formik.touched.IsRedemable && formik.errors.IsRedemable,
                            },
                            {
                                // 'is-valid': formik.touched.IsRedemable && !formik.errors.IsRedemable,
                            }
                        )}
                        checked={formik.values.IsRedemable}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.IsRedemable && formik.errors.IsRedemable && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{String(formik.errors.IsRedemable)}</span>
                            </div>
                        </div>
                    )}
                </div> */}
                <div className='fv-row col-lg-3 mb-8'>
                  <label htmlFor='IsOnline' className='form-label fs-6 fw-bolder text-dark'>
                    IsOnline
                  </label>
                  <input
                    id='IsOnline'
                    type='checkbox'
                    className={clsx(
                      'form-check-input mx-6',
                      {
                        'is-invalid': formik.touched.IsOnline && formik.errors.IsOnline,
                      },
                      {
                        // 'is-valid': formik.touched.IsOnline && !formik.errors.IsOnline,
                      }
                    )}
                    checked={formik.values.IsOnline}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.IsOnline && formik.errors.IsOnline && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{String(formik.errors.IsOnline)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3'
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
              <div
                onClick={() => navigate(-1)}
                className='btn btn-secondary fw-bolder fs-6 px-8 py-4 my-3 me-3'
              >
                Cancel
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* {serviceId ? ( */}
        <div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Partners</h3>
              </div>
              <div className='card-toolbar'>
                <div className='d-flex justify-content-end'>
                  <button type='button' className='btn btn-primary' onClick={openPartnerModal}>
                    <KTIcon iconName='plus' className='fs-2' />
                    Assign Partners
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className='card-body border-top p-9'>
                {ahPartnerOfServiceList.length > 0 ? (
                  <div>
                    <div className='bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive'>
                      <table className='table table-striped h-100'>
                        <thead>
                          <tr>
                            <th className='fw-bolder'>Partner Name</th>
                            <th className='fw-bolder'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ahPartnerOfServiceList.map((item: any, index) => (
                            <tr key={index}>
                              <td>{item.PartnerName ? item.PartnerName : '-'}</td>
                              <td>
                                <div className='d-flex'>
                                  <div
                                    style={{cursor: 'pointer', marginLeft: '20px'}}
                                    onClick={() => deleteAhPartnerService(item.ID)}
                                  >
                                    <i className='fa fa-trash fa-5x fs-2' />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : loading ? (
                  <span className='indicator-progress text-center' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <div className='text-center w-90'>
                    <h2 className='mt-9'>Records not found</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Partner Category</h3>
              </div>
              <div className='card-toolbar'>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={openPartnerCategoryModal}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Assign Partner Category
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className='card-body border-top p-9'>
                {ahPartnerCategoryOfServiceList.length > 0 ? (
                  <div>
                    <div className='bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive'>
                      <table className='table table-striped h-100'>
                        <thead>
                          <tr>
                            <th className='fw-bolder'>Category Name</th>
                            <th className='fw-bolder'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ahPartnerCategoryOfServiceList.map((item: any, index) => (
                            <tr key={index}>
                              <td>{item.CategoryName ? item.CategoryName : '-'}</td>
                              <td>
                                <div className='d-flex'>
                                  <div
                                    style={{cursor: 'pointer', marginLeft: '20px'}}
                                    onClick={() => deleteAhPartnerCategoryService(item.ID)}
                                  >
                                    <i className='fa fa-trash fa-5x fs-2' />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : loading ? (
                  <span className='indicator-progress text-center' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <div className='text-center w-90'>
                    <h2 className='mt-9'>Records not found</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    
      <Modal show={showPartnerModal} onHide={handlePartnerClose}>
        <Modal.Header closeButton>
          <Modal.Title>Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={partnerFormik.handleSubmit}>
            <div className='form-group'>
              <label htmlFor='categoryName'>Partner</label>
              <Select
                id='AHPartnerID'
                name='AHPartnerID'
                className={clsx('form-control bg-transparent p-0', {
                  'is-invalid':
                    partnerFormik.touched.AHPartnerID && partnerFormik.errors.AHPartnerID,
                })}
                getOptionLabel={(option) => option.PartnerName}
                getOptionValue={(option) => option.ID}
                value={
                  partnerFormik.values.AHPartnerID
                    ? ahPartnerList.find(
                        (option: any) => option.ID === partnerFormik.values.AHPartnerID
                      )
                    : null
                }
                options={ahPartnerList}
                styles={customStyles}
                onChange={(selectedOption: any) =>
                  partnerFormik.setFieldValue('AHPartnerID', selectedOption?.ID || '')
                }
                placeholder='Select Partner'
              />
              {partnerFormik.touched.AHPartnerID && partnerFormik.errors.AHPartnerID && (
                <div className='invalid-feedback'>{partnerFormik.errors.AHPartnerID}</div>
              )}
            </div>

            <Modal.Footer>
              <Button variant='secondary' onClick={handlePartnerClose}>
                Close
              </Button>
              <Button variant='primary' type='submit' disabled={submitAhPartnerService}>
                {!submitAhPartnerService ? 'Save' : 'Submitting'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showPartnerCategoryModal} onHide={handlePartnerCategoryClose}>
        <Modal.Header closeButton>
          <Modal.Title>Partner Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={partnerCategoryFormik.handleSubmit}>
            <div className='form-group'>
              <label htmlFor='categoryName'>Partner Category</label>
              <Select
                id='PartnerCategoryID'
                name='PartnerCategoryID'
                className={clsx('form-control bg-transparent p-0', {
                  'is-invalid':
                    partnerCategoryFormik.touched.PartnerCategoryID &&
                    partnerCategoryFormik.errors.PartnerCategoryID,
                })}
                getOptionLabel={(option) => option.CategoryName}
                getOptionValue={(option) => option.ID}
                value={
                  partnerCategoryFormik.values.PartnerCategoryID
                    ? ahPartnerCategoryList.find(
                        (option: any) =>
                          option.ID === partnerCategoryFormik.values.PartnerCategoryID
                      )
                    : null
                }
                options={ahPartnerCategoryList}
                styles={customStyles}
                onChange={(selectedOption: any) =>
                  partnerCategoryFormik.setFieldValue('PartnerCategoryID', selectedOption?.ID || '')
                }
                placeholder='Select Partner Category'
              />
              {partnerCategoryFormik.touched.PartnerCategoryID &&
                partnerCategoryFormik.errors.PartnerCategoryID && (
                  <div className='invalid-feedback'>
                    {partnerCategoryFormik.errors.PartnerCategoryID}
                  </div>
                )}
            </div>

            <Modal.Footer>
              <Button variant='secondary' onClick={handlePartnerCategoryClose}>
                Close
              </Button>
              <Button variant='primary' type='submit' disabled={submitAhPartnerCategoryService}>
                {!submitAhPartnerCategoryService ? 'Save' : 'Submitting'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
