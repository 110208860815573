import React from 'react';
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import DashboardStats from './core/DashboardStats'; 


const dashboardBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard', 
    isSeparator: false,
    isActive: false,
  },
];

const DashboardManagementPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadcrumbs}>Analytics Dashboard</PageTitle>
      <h2>Analytics Period</h2>
      <DashboardStats /> 
    </>
  );
};

export default DashboardManagementPage;