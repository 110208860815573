import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import RewardMasterList from './reward-master/RewardMasterList'




const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reward Master',
    path: '/apps/reward-master-management/rewards',
    isSeparator: false,
    isActive: false,
  }
]

const RewardMaster = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='rewards'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Reward Master</PageTitle>
          <RewardMasterList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/reward-master-management/rewards' />} />
  </Routes>
  )
}

export default RewardMaster
