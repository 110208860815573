import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth';
import * as authHelper from '../../../auth/core/AuthHelpers';
import { Modal, Button } from 'react-bootstrap';
import { KTIcon } from '../../../../../_metronic/helpers';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import '../../css/pagination.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import config from 'config'

const validationSchema = Yup.object().shape({
  DiagnosisName: Yup.string().transform((value) => value.trim()).required('Diagnosis Name is required'),
});

export default function DiagnosisList() {
  const { saveAuth, setCurrentUser } = useAuth();
  const TOKEN = authHelper.getAuth();
  const [loading, setLoading] = useState(true)
  const [DiagnosisList, setDiagnosisList] = useState([]);
  const [DiagnosisName, setDiagnosisName] = useState('');
  const [DiagnosisID, setDiagnosisID] = useState(null);
  const [editDiagnosis, setEditDiagnosis] = useState(false);
  const [submitDiagnosis, setSubmitDiagnosis] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;
  const [length, setLength] = useState(0);
  let pageCount = Math.ceil(length / pageSize);

  const handleShow = (item: any) => {
    setSubmitDiagnosis(false);
    setShowModal(true);
    setEditDiagnosis(true);
    formik.setValues({DiagnosisName:item.DiagnosisName})
    setDiagnosisName(item.DiagnosisName);
    setDiagnosisID(item.ID);
  };

  const openModal = () => {
    setSubmitDiagnosis(false);
    setShowModal(true);
  };

  const changePage = ({ selected }: any) => {
    const newPageNumber = selected + 1;
    setPageNumber(newPageNumber);
    getDiagnosis(newPageNumber);
  };

  const formik = useFormik({
    initialValues: {
      DiagnosisName: '',
    },
    validationSchema,
    onSubmit: (values) => {

      const trimmedDiagnosisName = values.DiagnosisName.trim();

      // Check if the DiagnosisName is empty after removing spaces
      if (trimmedDiagnosisName === '') {
        formik.setFieldError('DiagnosisName', 'Diagnosis Name is required');
        formik.setFieldValue('DiagnosisName','')
        return;
      }

      setSubmitDiagnosis(true);
      if (!editDiagnosis) {
        axios
          .post(
            `${config.SERVER_URL}/diagnosis/adddiagnosis`,
            { DiagnosisName:trimmedDiagnosisName },
            { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
          )
          .then((res) => {
            console.log(res);
            if (res.data.message) {
              handleClose();
              getDiagnosis(pageNumber);
            }
          }).catch((err:any)=>{
            console.log(err)
            setSubmitDiagnosis(false);
            formik.setFieldError('DiagnosisName',err.response.data.message)
          });
      } else {
        axios
          .put(
            `${config.SERVER_URL}/diagnosis/updatediagnosis/${DiagnosisID}`,
            { DiagnosisName:trimmedDiagnosisName },
            { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
          )
          .then((res) => {
            console.log(res);
            if (res.data.message) {
              handleClose();
              getDiagnosis(pageNumber);
            }
          }).catch((err:any)=>{
            console.log(err)
            setSubmitDiagnosis(false);
            formik.setFieldError('DiagnosisName',err.response.data.message)
          });
      }
    },
  });

  const deleteDiagnosis = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to delete this entry?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(`${config.SERVER_URL}/diagnosis/deletediagnosis/${id}`, {
                headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken },
              })
              .then((res) => {
                if (res.data.message) {
                  getDiagnosis(pageNumber);
                }
              });
          },
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setEditDiagnosis(false);
    setSubmitDiagnosis(false)
    setDiagnosisName('');
    setDiagnosisID(null);
    formik.resetForm();
  };

  const getDiagnosis = (pageNumber: any) => {
    setLoading(true)
    try {
      axios
        .get(
          `${config.SERVER_URL}/diagnosis/getalldiagnosiswithpagination?page=${pageNumber}&limit=${pageSize}`,
          { headers: { Authorization: 'Bearer ' + TOKEN?.AccessToken } }
        )
        .then((res: any) => {
          if (res.data) {
            setLoading(false)
            if (res.data.diagnosis.length !== 0) {
              setDiagnosisList(res.data.diagnosis);
              setLength(res.data.totalRecords);
            } else {
              if (pageNumber > 1) {
                getDiagnosis(pageNumber - 1);
                setPageNumber(pageNumber - 1);
              }
            }
          } else {
            saveAuth(undefined);
            setCurrentUser(undefined);
          }
        })
        .catch((err) => {
          console.log(err);
          setCurrentUser(undefined);
          saveAuth(undefined);
        });
    } catch (err) {
      console.log(err);
      setCurrentUser(undefined);
      saveAuth(undefined);
    }
  };

  useEffect(() => {
    getDiagnosis(1);
  }, []);

  return (
    <>
      <div className="m-0 p-0 h-100">
        <div className="col-lg-18 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-description d-flex justify-content-end">
                <button type="button" className="btn btn-primary" onClick={openModal}>
                  <KTIcon iconName="plus" className="fs-2" />
                  Add Diagnosis
                </button>
              </p>
              {DiagnosisList.length > 0 ? (
                <div>
                  <div className="bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive">
                    <table className="table table-striped h-100">
                      <thead>
                        <tr>
                          <th className='fw-bolder'>Diagnosis Name</th>
                          <th className='fw-bolder'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DiagnosisList.map((item: any, index) => (
                          <tr key={index}>
                            <td>{item.DiagnosisName ? item.DiagnosisName : '-'}</td>
                            <td>
                              <div className="d-flex">
                                <div style={{ cursor: 'pointer' }} onClick={() => handleShow(item)}>
                                  <i className="fa fa-edit fa-5x fs-2" />
                                </div>
                                <div style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => deleteDiagnosis(item.ID)}>
                                  <i className="fa fa-trash fa-5x fs-2" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="m-auto mt-6">
                    {DiagnosisList.length > 0 && (
                      <ReactPaginate
                        previousLabel="Prev"
                        nextLabel="Next"
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName="paginationbtns"
                        previousLinkClassName="prevBtn"
                        nextLinkClassName="nextBtn"
                        disabledClassName="paginationDisabled"
                        activeClassName="paginationActive"
                      />
                    )}
                  </div>
                </div>
              ):  loading ? (
                <span className='indicator-progress text-center' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ):(
                <div className="text-center w-90">
                  <h2 className="mt-9">Records not found</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editDiagnosis ? 'Edit Diagnosis' : 'Add Diagnosis'}</Modal.Title>
        </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <label className="col-lg-4 col-form-label fw-bold fs-6"></label>
          </div>
          <div className="form-group">
            <label htmlFor="DiagnosisName">Diagnosis Name</label>
            <input
              type="text"
              className={`form-control ${formik.touched.DiagnosisName && formik.errors.DiagnosisName ? 'is-invalid' : ''}`}
              id="DiagnosisName"
              name="DiagnosisName"
              value={formik.values.DiagnosisName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.DiagnosisName && formik.errors.DiagnosisName && (
              <div className="invalid-feedback">{formik.errors.DiagnosisName}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={submitDiagnosis} type="submit">
            {!submitDiagnosis ? 'Save' : 'Submitting'}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
