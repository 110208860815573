import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../auth'
import * as authHelper from '../../../../auth/core/AuthHelpers'
// import { Modal, Button } from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactPaginate from 'react-paginate'
import '../../../css/pagination.css'
import {getBucketURL} from '../../../../../utils/Index'
import config from 'config'

export default function FamousPeopleList() {
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(true)
  const TOKEN = authHelper.getAuth()
  const [famousPeopleList, setfamousPeopleList] = useState([])
  let [pagenumber, setPagenumber] = useState(1)
  let [searchItem, setsearchItem] = useState('')
  const pagesize = 10
  const [length, setLength] = useState(0)
  let pageCount = Math.ceil(length / pagesize)

  const AddPeople = () => {
    navigate('/apps/famous-peoples-management/famous-peoples/add-people')
  }
  const EditPeople = (id: string) => {
    navigate('/apps/famous-peoples-management/famous-peoples/edit-people/' + id)
  }
  const changePage = ({selected}: any) => {
    setPagenumber(selected + 1)
    pagenumber = selected + 1
    getfamousPeopleList(pagenumber)
  }

  const deleteFamousPeople = (id: any) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'you want to delete this entry ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios
              .delete(
                `${config.SERVER_URL}/famouspeoples/deletefamouspeople/${id}`,
                {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
              )
              .then((res) => {
                if (res.data.message) {
                  getfamousPeopleList(pagenumber)
                }
              })
          },
        },
        {
          label: 'Cancel',
        },
      ],
    })
  }

  const getfamousPeopleList = (pagenumber: any) => {
    setLoading(true)
    try {
      axios
        .get(
          config.SERVER_URL +
            `/famouspeoples/getallfamouspeoplewithpagination?page=${pagenumber}&limit=${pagesize}&search=${searchItem}`,
          {headers: {Authorization: 'Bearer ' + TOKEN?.AccessToken}}
        )
        .then((res: any) => {
          console.log(res)
          if (res.data) {
            setLoading(false)
            if (res.data.famousPeople.length !== 0) {
              setfamousPeopleList(res.data.famousPeople)
              setLength(res.data.totalRecords)
            } else {
              if (pagenumber > 1) {
                getfamousPeopleList(pagenumber - 1)
                setPagenumber(pagenumber - 1)
              }
            }
          } else {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
        .catch((err) => {
          console.log(err)
          setCurrentUser(undefined)
          saveAuth(undefined)
        })
    } catch (err) {
      console.log(err)
      setCurrentUser(undefined)
      saveAuth(undefined)
    }
  }
  const SearchData = (e: any) => {
    setsearchItem(e.target.value)
    searchItem = e.target.value
    getfamousPeopleList(1)
  }
  useEffect(() => {
    getfamousPeopleList(1)
  }, [])

  return (
    <div className='m-0 p-0 h-100'>
      <div className='col-lg-18 grid-margin stretch-card '>
        <div className='card'>
          <div className='card-body'>
            <p className='card-description d-flex justify-content-between'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder='Search..'
                  value={searchItem}
                  onChange={SearchData}
                />
              </div>
              <button type='button' className='btn btn-primary' onClick={AddPeople}>
                <KTIcon iconName='plus' className='fs-2' />
                Add Famous People
              </button>
            </p>
            {famousPeopleList.length > 0 ? (
              <div>
                <div className='bg-white rounded-3 ps-9 pe-9 pt-5 pb-5 table-responsive'>
                  <table className='table table-striped h-100'>
                    <thead>
                      <tr>
                        <th className='fw-bolder'>Profile</th>
                        <th className='fw-bolder'>Name</th>
                        <th className='fw-bolder'>Country</th>
                        <th className='fw-bolder'>DiagnosisName</th>
                        <th className='fw-bolder'>Categoty</th>
                        <th className='fw-bolder'>Source</th>
                        <th className='fw-bolder'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {famousPeopleList.map((item: any, index) => {
                        return (
                          <RenderImage
                            item={item}
                            index={index}
                            deleteFamousPeople={deleteFamousPeople}
                            EditPeople={EditPeople}
                            key={index}
                            famousPeopleList={famousPeopleList}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className='m-auto mt-6'>
                  {famousPeopleList.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'Prev'}
                      nextLabel={'Next'}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={'paginationbtns'}
                      previousLinkClassName={'prevBtn'}
                      nextLinkClassName={'nextBtn'}
                      disabledClassName={'paginationDisabled'}
                      activeClassName={'paginationActive'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : loading ? (
              <span className='indicator-progress text-center' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <div className='text-center w-90'>
                <h2 className='mt-9'>Records not found</h2>
              </div>
            )}{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderImage = (props: any) => {
  const {item, index, EditPeople, deleteFamousPeople, famousPeopleList} = props
  const [image, setImage] = useState('')

  useEffect(() => {
    getURl(item?.Image)
  }, [famousPeopleList])

  const getURl = async (url: any) => {
    let imageUrl = await getBucketURL(url)
    setImage(imageUrl)
  }
  return (
    <tr key={index}>
      <td>
        {/* <div
          className='image-input '
          data-kt-image-input='true'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
        >
          <div
            className='image-input-wrapper w-35px h-35px '
            style={{
              backgroundImage: `url(${image ? image : toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
            }}
          ></div>
        </div> */}
        <div>
          <img
            src={image}
            alt='famous people category image'
            style={{width: '50px', height: '50px', objectFit: 'contain'}}
            loading='lazy'
          />
        </div>
      </td>
      <td>{item.Name ? item.Name : '-'}</td>
      <td>{item.Country ? item.Country : '-'}</td>
      <td>{item.DiagnosisName ? item.DiagnosisName : '-'}</td>
      <td>{item.CategoryName ? item.CategoryName : '-'}</td>
      <td>{item.source ? item.source : '-'}</td>

      <td>
        <div className='d-flex '>
          <div style={{cursor: 'pointer'}} onClick={() => EditPeople(item.ID)}>
            <i className='fa fa-edit fa-5x fs-2' />
          </div>

          <div
            style={{cursor: 'pointer', marginLeft: '20px'}}
            onClick={() => deleteFamousPeople(item.ID)}
          >
            {' '}
            <i className='fa fa-trash fa-5x fs-2' />
          </div>
        </div>
      </td>
    </tr>
  )
}
