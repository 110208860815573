import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import BodyPartList from './body-part/BodyPartList'



const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Body Parts',
    path: '/apps/body-parts-management/bodyparts',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BodyParts = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='bodyparts'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Body Parts</PageTitle>
          <BodyPartList/>
          </>
        }
      />
   
    </Route>
   
    <Route index element={<Navigate to='/apps/body-parts-management/bodyparts' />} />
  </Routes>
  )
}

export default BodyParts
