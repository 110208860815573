import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import AhPartenrCategoryList from './ah-partner-category/AhPartnerCategoryList'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'AH partner category',
    path: '/apps/ah-partner-category-management/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AhPartenrCategory = () => {
  return (
    <Routes>
    <Route element={<Outlet />}>
      <Route
        path='categories'
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>AH Partner category</PageTitle>
           <AhPartenrCategoryList/>
          </>
        }
      />
    </Route>
   
    <Route index element={<Navigate to='/apps/ah-partner-category-management/categories' />} />
  </Routes>
  )
}

export default AhPartenrCategory
